import React, { useState } from 'react';

const BucadorJ = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchDate, setSearchDate] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    onSearch(searchTerm, searchDate); // Llama la función de búsqueda con los valores ingresados
  };

  return (

    <div className="TicketModule">
      <h2>Search Tickets by Event Day</h2>
      <form onSubmit={handleSearch}>
        <div>
        
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Enter Event Day"
          />
        </div>
        
       <button type="text" className="buscarj-button bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded shadow transition duration-300 ease-in-out">
 Search🔍
</button>
      </form>
    </div>
  );
};

export default BucadorJ;

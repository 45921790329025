import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ModalSellarBoleto.css';
import { useTranslation } from 'react-i18next';
import { validarSeleccionCaballos, validarCarrerasYMultiplicador, validarAutenticacionYJornada } from './components/validaciones';
import { construirMemo } from './components/construirMemo';
import { enviarTransaccionKeychain } from './components/transaccionKeychain';

const ModalSellarBoleto = ({ isOpen, closeModal, recentRaces }) => {
  const [errorMessage, setErrorMessage] = useState('');


  // console.log("data en el modal",recentRaces)
  const [selectedHorses, setSelectedHorses] = useState(recentRaces.map(() => []));
  // const [selectedHorses, setSelectedHorses] = useState(Array(recentRaces.length).fill([]));
  const[jornada,setJornada]=useState("")
  const baseMultiplicador = 0.01;
  const { t } = useTranslation();



   // Función para verificar que todas las jornadas son iguales
  const checkConsistentJornadas = () => {
    if (recentRaces.length === 0) return true;

    const firstJornada = recentRaces[0].jornada;
    const allSame = recentRaces.every(race => race.jornada === firstJornada);

    if (!allSame) {
       setErrorMessage('Error: Las jornadas no son consistentes en todas las carreras recientes.');
    }
      // alert('Success: Las jornada es consistentes ',firstJornada);
     setJornada(firstJornada)
    return allSame;
  };



  useEffect(() => {

    console.log("Disparo uEf en ModalSellarBoleto checkConsistentJornadas")
    if (isOpen) {
      // Llama a la verificación cuando se abra el modal
      const isConsistent = checkConsistentJornadas();

      // Si no es consistente, podrías cerrar el modal o prevenir acciones adicionales
      if (!isConsistent) {
        closeModal();
      }
    }
  }, [isOpen, recentRaces, closeModal]);
  

  const handleSelectHorse = (raceIndex, horseNumber) => {
    const newSelectedHorses = [...selectedHorses];
    const raceSelections = newSelectedHorses[raceIndex];

    // Toggle selección del caballo en la carrera actual
    if (raceSelections.includes(horseNumber)) {
      newSelectedHorses[raceIndex] = raceSelections.filter(num => num !== horseNumber);
    } else {
      newSelectedHorses[raceIndex] = [...raceSelections, horseNumber];
    }

    setSelectedHorses(newSelectedHorses);
  };




  const calculateMultiplicador = () => {
    const multiplicador = selectedHorses.reduce((acc, curr) => acc * (curr.length || 1), baseMultiplicador);
    return multiplicador;
  };

// const handleBuyHorses = async () => {
//   // Validar que cada carrera tenga al menos un caballo seleccionado
//   const hasEmptyRace = selectedHorses.some(horses => horses.length === 0);
//   if (hasEmptyRace) {
//     alert('Debes seleccionar al menos un caballo para cada carrera.');
//     return; // Salir de la función si hay una carrera sin selección
//   }

//   // Construir el JSON personalizado
//   const customJson = {
//     selectedHorses,
//     multiplier: calculateMultiplicador(),
//   };

//   const costoRegistro = calculateMultiplicador(); // Ejemplo de cálculo del costo de registro
//   const CuentaPool = 'bhr-pick6'; // Reemplaza con la cuenta real

//   // Construir el memo detallado
//   let memo = `BHR`;
//   recentRaces.forEach((race, index) => {
//     const selected = selectedHorses[index].join(', ');
//     memo += `${race.raceid}:- ${selected}\n  BHR`;
//   });
//   memo += `Total: ${calculateMultiplicador().toFixed(3)} // j-${jornada}`;

//   if (window.hive_keychain) {
//     const keychain = window.hive_keychain;
//     keychain.requestSendToken(
//       localStorage.getItem('user'), // El usuario que está enviando el token
//       CuentaPool, // La cuenta que recibe el token
//       costoRegistro.toFixed(3), // Monto del token
//       memo, // Memo para la transacción
//       'SWAP.HIVE', // Símbolo del token
//       (response) => {
//         console.log('Respuesta de Keychain:', response);
//         if (response.error) {
//           console.error('Error al realizar la transacción:', response.error);
//           alert('Error al realizar la transacción: ' + response.error);
//         } else {
//           axios.post('/api/transaction', {
//             customJson,
//             costo_registro: costoRegistro,
//             CuentaPool,
//           })
//           .then(res => {
//             console.log('Transacción enviada con éxito:', res.data);
//             closeModal(); // Cierra el modal después de comprar
//           })
//           .catch(err => {
//             console.error('Error al enviar la transacción al backend:', err);
//           });
//         }
//       }
//     );
//   } else {
//     alert('Hive Keychain no está instalado');
//   }
// };




// const handleBuyHorses = async () => {

//     setErrorMessage('');
//   // 1. Validar que `selectedHorses` no esté vacío
//   if (!selectedHorses || selectedHorses.length === 0) {
//     alert('No se han seleccionado caballos.');
//     return;
//   }

//   // 2. Validar que cada carrera tenga al menos un caballo seleccionado
//   const hasEmptyRace = selectedHorses.some(horses => horses.length === 0);
//   if (hasEmptyRace) {
//     alert('Debes seleccionar al menos un caballo para cada carrera.');
//     return; // Salir de la función si hay una carrera sin selección
//   }

//   // 3. Validar que `recentRaces` y `selectedHorses` tengan la misma longitud
//   if (recentRaces.length !== selectedHorses.length) {
//     alert('El número de carreras no coincide con el número de caballos seleccionados.');
//     return;
//   }

//   // 4. Validar que `calculateMultiplicador` devuelva un valor válido
//   const multiplicador = calculateMultiplicador();
//   if (isNaN(multiplicador) || multiplicador <= 0) {
//     alert('El multiplicador calculado no es válido.');
//     return;
//   }

//   // 5. Validar que el usuario esté autenticado (por ejemplo, que exista en el localStorage)
//   const user = localStorage.getItem('user');
//   if (!user) {
//     alert('El usuario no está autenticado.');
//     return;
//   }

//   // 6. Validar que la jornada esté definida y sea válida
//   if (!jornada || typeof jornada !== 'number' || jornada <= 0) {
//     alert('La jornada no es válida.');
//     return;
//   }

//   // 7. Construir el JSON personalizado
//   const customJson = {
//     selectedHorses,
//     multiplier: multiplicador,
//   };

//   const costoRegistro = multiplicador; // Ejemplo de cálculo del costo de registro
//   const CuentaPool = 'bhr-pick6'; // Reemplaza con la cuenta real

//   // 8. Construir el memo detallado
//   let memo = `BHR`;
//   recentRaces.forEach((race, index) => {
//     const selected = selectedHorses[index].join(', ');
//     memo += `${race.raceid}:- ${selected}\n  BHR`;
//   });
//   memo += `Total: ${costoRegistro.toFixed(3)} // j-${jornada}`;

//   // 9. Validar que Hive Keychain esté disponible
//   if (!window.hive_keychain) {
//     alert('Hive Keychain no está instalado');
//     return;
//   }

//   // 10. Enviar la transacción usando Hive Keychain
//   window.hive_keychain.requestSendToken(
//     user, // El usuario que está enviando el token
//     CuentaPool, // La cuenta que recibe el token
//     costoRegistro.toFixed(3), // Monto del token
//     memo, // Memo para la transacción
//     'SWAP.HIVE', // Símbolo del token
//     (response) => {
//       console.log('Respuesta de Keychain:', response);
//       if (response.error) {
//         console.error('Error al realizar la transacción:', response.error);
//         alert('Error al realizar la transacción: ' + response.error);
//       } else {
//         // 11. Enviar la transacción al backend si la transacción de Keychain fue exitosa
//         axios.post('/api/transaction', {
//           customJson,
//           costo_registro: costoRegistro,
//           CuentaPool,
//         })
//         .then(res => {
//           console.log('Transacción enviada con éxito:', res.data);
//           closeModal(); // Cierra el modal después de comprar
//         })
//         .catch(err => {
//           console.error('Error al enviar la transacción al backend:', err);
//         });
//       }
//     }
//   );
// };







const handleBuyHorses = async (    closeModal) => {
  setErrorMessage(''); // Resetear el mensaje de error
  console.log("selectedHorses,selectedHorses",selectedHorses)

  // Validaciones
  const errorSeleccion = validarSeleccionCaballos(selectedHorses);
  if (errorSeleccion) {
    setErrorMessage(errorSeleccion);
    return;
  }

  const multiplicador =  calculateMultiplicador();
  if (!multiplicador) {
    setErrorMessage('El multiplicador calculado no es válido.');
    return;
  }
  console.log("Multipliecador es ", multiplicador )
  console.log("Jornada ", jornada )

  const { error, user } = validarAutenticacionYJornada(jornada);
  if (error) {
    setErrorMessage(error);
    return;
  }

  // Construir el JSON personalizado
  const customJson = {
    selectedHorses,
    multiplier: multiplicador,
  };

  const costoRegistro = multiplicador; // Ejemplo de cálculo del costo de registro
  const CuentaPool = 'bhr-pick6'; // Reemplaza con la cuenta real

  // Construir el memo
  const memo = construirMemo(recentRaces, selectedHorses, costoRegistro, jornada);

  // Enviar la transacción usando Hive Keychain
  enviarTransaccionKeychain(user, CuentaPool, costoRegistro, memo, customJson, closeModal, setErrorMessage);
};


  const getRaceLink = (raceId) => {
    return `https://www.blockhorseracing.com/races/#raceId=${raceId}`;
  };

  if (!isOpen) return null;

 return (
  <div className="modalboleto-overlay">
    <div className="modalboleto-content">
      <div className="modal-header">
        <h2>{t('SellarBoleto.title')}</h2>

        <a
          href="https://docs-en.blockhorseracing.com/additional-game/6-in-a-row"
          className="rules-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('SellarBoleto.Rules')}
        </a>
      </div>
      <h5>{t('SellarBoleto.Jornada')} {jornada}</h5>




      {/* Verificar si alguna carrera no está abierta */}
      {recentRaces.some(race => race.status_superior !== 'abierta') ? (
        <div className="warning-message">
        {t('SellarBoleto.JugadaClose')}
        </div>
      ) : (
        <>
          {recentRaces.map((race, raceIndex) => (
            <div key={race.raceid} className="race-selection">
              <h3>
                Race - {race.raceid}
                <a
                  href={getRaceLink(race.raceid)}
                  className="info-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Ver detalles de la carrera"
                >
                  ℹ️
                </a>
              </h3>
              <ul className="horse-options">
                {Array.from({ length: 8 }).map((_, horseNumber) => (
                  <li key={horseNumber}>
                    <label>
                      <input
                        type="checkbox"
                        value={horseNumber }
                        checked={selectedHorses[raceIndex].includes(horseNumber )}
                        onChange={() => handleSelectHorse(raceIndex, horseNumber )}
                      />
                      {horseNumber}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <p>Multiplier: {baseMultiplicador}</p>
          <p>Total: {calculateMultiplicador().toFixed(3)}</p>
          <button onClick={handleBuyHorses} className="buy-button">
            {t('SellarBoleto.SealTicket')}
          </button>
        </>
      )}

      <button onClick={closeModal} className="close-button">
        {t('general.Close')}
      </button>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  </div>
);

};

export default ModalSellarBoleto;

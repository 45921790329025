import React, { useEffect, useState } from 'react';

import Icon from './../../generals/svgiconos/svgiconosenreact.jsx';
import Loader from './../../generals/loders/LoaderRunHorse.jsx'; // Asegúrate de importar tu componente Loader
// import ModalPatrocinarBallo from './modals/ModalPatrocinarBallo.jsx'; // Importar el modal
import ModalPatrocinarBallo from './modals/ModalPatrocinarBallo.jsx'; // Importar el modal
import HorseCard from './cards/HCardTest.jsx'
import SponsorHorseCard from'./cards/SponsorHorseCard.jsx'
const SponsorList = ({ horses,horsesHabilitados, openModal,loadingHorse,loadingHorseB  }) => {
        console.log("habilitados pormi w2.0 NO" , horses)
    
        console.log("habilitados pormi w2.0" , horsesHabilitados)

  const [currentSeasonTickets, setCurrentSeasonTickets] = useState([]);
  const [currentHorses, setCurrentHorses] = useState([]);
  const [activeSection, setActiveSection] = useState('tickets'); // Estado para alternar secciones
  const [selectedHorse, setSelectedHorse] = useState([]); // Estado para el ticket seleccionado
  // const [isModalSponsorOpen, setIsModalSponsorOpen] = useState(false); // Estado para modalBoletoIndividual
  // const [searchedTickets, setSearchedTickets] = useState([]); // Estado para los boletos buscados
  // const [loadingHorse, setLoadingHorse] = useState(true);
  const [isVisibleMail,setIsVisibleMail]= useState(false);
  const [user, setUser] = useState(localStorage.getItem("user"));




  // Función para abrir modal con el boleto seleccionado
  const openModalSponsorBallo = (horse) => {
    setSelectedHorse(horse)
    if (selectedHorse){
    // setIsModalSponsorOpen(true); // Abrir modal
    setIsVisibleMail(true)
 } };


  const toggleOpenMail = () => {
    setIsVisibleMail(!isVisibleMail)
    // setIsModalSponsorOpen(!isModalSponsorOpen);
  };




  // Función para cerrar modal
  const closeModalSponsorBallo = () => {
    setIsVisibleMail(false);
    setSelectedHorse(null);  // Limpiar el ticket seleccionado
  };

  return (

    <div className="TicketModule">

   
      <h2>Horses available for sponsorship</h2>
    <div className="SponsorModule">
      {loadingHorse ? (
        <Loader />
      ) : (
        <ul>
          {Array.isArray(horses) && horses.length > 0 ? (
            horses.map(horse => (
              <SponsorHorseCard
                key={horse.equineId}
                horse={horse}
                openModalSponsorBallo={openModalSponsorBallo}
              />
            ))
          ) : (
            <p>There are no horses available for sponsorship</p>
          )}
        </ul>
      )}
    </div>
  

 <h2>Horses you've enabled for this tournament</h2>
        {loadingHorseB ? ( // Mostrar loader mientras se cargan los datos
        <Loader /> // Asegúrate de tener un componente Loader
      ) : (
       <div className="SponsorModule">
     <div className="app">
      {horsesHabilitados.map((horse, index) => (
        <HorseCard key={index} horse={horse} />
      ))}
    </div>
      </div>




        )}





         {/* Modal Boleto Individual */}
      {isVisibleMail && (
        <ModalPatrocinarBallo
          closeModal={closeModalSponsorBallo}
          toggleOpenMail={toggleOpenMail}
          horses={selectedHorse}
          isVisibleMail={isVisibleMail}
        />
      )}
    </div>
  );
};



export default SponsorList;

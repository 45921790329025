import { linkApp } from './../../configuracion/variablesPublicas.jsx';

// Función para obtener torneos con inscripciones abiertas
export const get_Torneo = async () => {
  try {
    // Realiza la solicitud GET al endpoint correspondiente
    const response = await fetch(`${linkApp}/registro_torneo?status_inscriciones=abierto`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Error al obtener los torneos');
    }

    const data = await response.json();
    return { success: true, data };
  } catch (error) {
    console.error('Error al obtener los torneos abiertos:', error);
    return { success: false, error: error.message };
  }
};





import React from 'react';
import loadingGif from './../../../assets/gif/loadee/runing_horse_p.gif'; // Ajusta la ruta según tu estructura de archivos
import './LoaderRunH.css';

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader-background">
        <img src={loadingGif} alt="Loading..." className="loading-gifH" />
        <p className="loadingH-text">Loading...</p>
      </div>
    </div>
  );
};

export default Loader;


// RegistroNotificacion.js
import React from "react";
 import './../css/RegistroNotificacion.css';


const RegistroNotificacion = ({ mensaje, tipo }) => {
  return (
    <div className={`registro-notificacion ${tipo}`}>
      <p>{mensaje}</p>
    </div>
  );
};

export default RegistroNotificacion;

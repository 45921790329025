// NavItem.jsx
import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NavItem({ to, icon, label, closeMenu, activeStyle }) {
  return (
    <li>
      <NavLink
        end
        to={to}
        onClick={closeMenu}
        style={({ isActive }) => (isActive ? {...activeStyle, textDecoration: "none"} : { textDecoration: "none" })}
      >
        <button className="nav-button">
          <span className="nav-button-icon">{icon}</span>
          {label}
        </button>
      </NavLink>
    </li>
  );
}

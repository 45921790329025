import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { linkApp } from './../generals/configuracion/variablesPublicas.jsx';

import TicketList from './components/TicketList';
import SponsorList from './components/SponsorList';
import ToggleButtons from './components/ToggleButtons';
import BuscadorJ from'./components/BuscadorJ.jsx' //Buscador de Boletos por Jornada

import ModalBoletoIndividual from './components/ModalBoletoIndividual'; // Importar el modal
import './components/css/TicketModule.css'; // Importar el CSS general

const TicketModule = () => {
  const [currentSeasonTickets, setCurrentSeasonTickets] = useState([]);
  const [currentHorses, setCurrentHorses] = useState([]);
  const [horsesHabilitados, setHorsesHabilitados] = useState([]);
  const [activeSection, setActiveSection] = useState('tickets'); // Estado para alternar secciones
  const [selectedTicket, setSelectedTicket] = useState(null); // Estado para el ticket seleccionado
  const [isModalBoletoOpen, setIsModalBoletoOpen] = useState(false); // Estado para modalBoletoIndividual
  const [searchedTickets, setSearchedTickets] = useState([]); // Estado para los boletos buscados
  const [loadingHorse, setLoadingHorse] = useState(true);
  const [loadingHorseB, setLoadingHorseB] = useState(true);

  const [isOpenModalPatrocinarTorneoBallo,setIsOpenModalPatrocinarTorneoBallo]= useState(false);
  const [user, setUser] = useState(localStorage.getItem("user"));



  // Cargar boletos de la jornada actual
  useEffect(() => {
    const fetchCurrentSeasonTickets = async () => {
      let query = localStorage.getItem("user");
      try {
        const response = await axios.get(`${linkApp}/boletos?usuario=${query}&status=abierto`);
        setCurrentSeasonTickets(response.data.body);
      } catch (error) {
        console.error('Error al cargar los boletos de la jornada actual:', error);
      }
    };
    fetchCurrentSeasonTickets();
  }, []);

  // Cargar caballos disponibles para patrocinar
  useEffect(() => {
    const fetchCurrentHorse = async () => {
      setLoadingHorse(true);
      try {
        const response = await axios.get(`${linkApp}/equino/habilitar_equino?habilitado=no&status_oficial=S`);
        setCurrentHorses(response.data.body);
      } catch (error) {
        console.error('Error al cargar los caballos para patrocinar:', error);
      }finally {
        setLoadingHorse(false);
      }
    };
    fetchCurrentHorse();
  }, []);



useEffect(() => {
    const fetchHHorse = async () => {
      setLoadingHorseB(true);
      let query = localStorage.getItem("user");

      try {
        const respons = await axios.get(`${linkApp}/equino/habilitar_equino?habilitado=si&status_oficial=I&habilitador=${user}`);
        setHorsesHabilitados(respons.data.body);
        console.log("habilitados pormi" , respons.data.body)
      } catch (error) {
        console.error('Error al cargar los caballos para patrocinar:', error);
      }finally {
        setLoadingHorseB(false);
      }
    };
    fetchHHorse();
  }, []);


  // Función para abrir modal con el boleto seleccionado
  const openModalBoletoIndividual = (ticket) => {
    setSelectedTicket(ticket);  // Guardar el ticket seleccionado
    setIsModalBoletoOpen(true); // Abrir modal
  };

  // Función para cerrar modal
  const closeModalBoletoIndividual = () => {
    setIsModalBoletoOpen(false);
    setSelectedTicket(null);  // Limpiar el ticket seleccionado
  };


  const searchTickets = async (searchTerm, searchDate) => {
  try {
    const response = await axios.get(`${linkApp}/boletos`, {
      params: {
        jornada: searchTerm,
      },
    });
    setSearchedTickets(response.data.body); // Guardar los resultados de la búsqueda
  } catch (error) {
    console.error('Error al buscar boletos:', error);
  }
};



  return (
    <div>
      <ToggleButtons activeSection={activeSection} setActiveSection={setActiveSection} />

      {/* Renderizar secciones según el estado activo */}
 {activeSection === 'tickets' && (
  <>
    <BuscadorJ onSearch={searchTickets} /> {/* Agregar el formulario de búsqueda */}
    {searchedTickets.length > 0 ? (
      <TicketList tickets={searchedTickets} openModal={openModalBoletoIndividual} />
    ) : (
      <TicketList tickets={currentSeasonTickets} openModal={openModalBoletoIndividual} />
    )}
  </>
)}
      {activeSection === 'sponsor' && (
        <SponsorList horses={currentHorses}  horsesHabilitados={horsesHabilitados}  openModal={openModalBoletoIndividual} loadingHorse={loadingHorse} loadingHorseB={loadingHorseB} />
      

      )}



      {/* Modal Boleto Individual */}
      {isModalBoletoOpen && (
        <ModalBoletoIndividual
          ticket={selectedTicket}
          onClose={closeModalBoletoIndividual}
        />
      )}
    </div>
  );
};

export default TicketModule;

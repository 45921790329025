import React from 'react';
import EquineImage from './../../../../../../generals/herramientas/imagenesCaballos.jsx';
import "./../css/horseinfo/HorseImage.css";

const HorseImage = ({ imageSrc, backupSrc, className }) => (
  <div className={`caballo-info-imagen-${className}`}>
    <EquineImage
      mainImageSrc={imageSrc}
      backupImageSrc={backupSrc}
      className={`caballo-info-imagen-${className}`} /* Aplica la clase para estilos específicos */
    />
  </div>
);

export default HorseImage;

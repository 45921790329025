import React, { useState } from 'react';

const EquineImage = ({ mainImageSrc, backupImageSrc, className }) => {
  const [imageErrorMain, setImageErrorMain] = useState(false);
  const [imageErrorBackup, setImageErrorBackup] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleImageErrorMain = () => {
    setImageErrorMain(true);
  };

  const handleImageErrorBackup = () => {
    setImageErrorBackup(true);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    console.log("EEEEEEEEEEEEEEEXXXXXXINOS");
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (

    
    <>
      {imageErrorMain && imageErrorBackup ? (
        <img
          src="/image/equinos/000.png" // Ruta de la tercera imagen
          alt="Error"
          className={`${className} ${isHovered ? 'hovered' : ''}`}
        />
      ) : imageErrorMain ? (
        <img
          src={backupImageSrc}
          alt="Error"
          className={`${className} ${isHovered ? 'hovered' : ''}`}
          onError={handleImageErrorBackup}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      ) : (
        <img
          src={mainImageSrc}
          alt="Img"
          className={`${className} ${isHovered ? 'hovered' : ''}`}
          onError={handleImageErrorMain}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      )}
    </>
  );
};

export default EquineImage;



import React from 'react';
import EquineImage from './../EquineImage';
import LastRacesEq from './LastRacesEq';
import Apuestas from './../Apuestas';
import EquinoStats from './EquinosStats';
import EquineBasicInfo from './EquineBasicInfo.jsx';

import { useTranslation } from 'react-i18next';


const EquinoRow = ({ equino, index, raceId, apuestas, fetchApuestas }) => {
   const { t } = useTranslation();
  
  const sumaTicketsPorEquino = apuestas.apuestas.reduce((acc, apuesta) => {
    if (apuesta.equinoId === equino.equineId) return acc + apuesta.cantidadTickets;
    return acc;
  }, 0);

  return (
    <tr>
      <td>
        {/* Propietario y detalles básicos */}
        <div>{equino.account} 👑 </div>
          <h1>{index}</h1>
        <div>
          {equino.habilitado === "si" && equino.status_oficial === "R" && (
            <>📜{equino.habilitador}</>
          )}
        </div>
        <div>{t('apuestas.ESupport')} {(sumaTicketsPorEquino/20).toFixed(4)}</div>
      </td>

      <td>
   
          <EquineBasicInfo equino={equino} />
      </td>

      <td>
        {/* Estadísticas del caballo */}
        <EquinoStats equino={equino} />
      </td>

      <td>
        {/* Últimas carreras */}
        <LastRacesEq caballo={equino} />
      </td>

      <td>
        {/* Apuestas */}
        <Apuestas equino={{ equineId: equino.equineId, name: equino.name, raceId, apuestas }} fetchApuestas={fetchApuestas} />
      </td>
    </tr>
  );
};

export default EquinoRow;

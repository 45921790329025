import React, { useState } from 'react';
import './css/ModalBoletoIndividual.css';

const ModalBoletoIndividual = ({ ticket, onClose }) => {
  const [showRaces, setShowRaces] = useState(false); // Estado para controlar la visibilidad de las carreras
console.log(ticket)
  if (!ticket) return null; // Si no hay ticket, no mostrar nada

  const toggleRaces = () => {
    setShowRaces(!showRaces); // Alternar entre mostrar y ocultar carreras
  };

  // const renderRaces = () => {
  //   return Array.from({ length: 6 }, (_, i) => {
  //     const raceKey = `race_${i + 1}`; // Genera race_1, race_2, ..., race_6
  //     const race = ticket[raceKey]; // Obtén los datos de la carrera
  //     if (!race || race.length === 0) return null;

  //     // Muestra el primer número (número de carrera) y los caballos elegidos
  //     return (
  //       <li key={raceKey}>
  //         <strong>{race[0]}:</strong> {race.slice(1).join(', ')} {/* Muestra el resto de los caballos */}
  //       </li>
  //     );
  //   });
  // };


  const renderRaces = () => {
  return Array.from({ length: 6 }, (_, i) => {
    const raceKey = `race_${i + 1}`; // Genera race_1, race_2, ..., race_6
    const race = ticket[raceKey] || []; // Asigna un array vacío si no hay datos
    if (race.length === 0) return null;

    // Muestra el primer número (número de carrera) y los caballos elegidos
    return (
      <li key={raceKey}>
        <strong>{race[0]}:</strong> {race.slice(1).join(', ')} {/* Muestra el resto de los caballos */}
      </li>
    );
  });
};


  // Función que muestra los premios si el estado es "Payed"
  const renderPremios = () => {
    const premios = [];

    // Verifica si status_p_1 es "Payed" y muestra symbol_1 y premio_1
    if (ticket.status_p_1 === 'Payed') {
      premios.push(
        <li key="premio_1">
          <strong>Premio 1:</strong> {ticket.premio_1} {ticket.symbol_1}
        </li>
      );
    }

    // Verifica si status_p_2 es "Payed" y muestra symbol_2 y premio_2
    if (ticket.status_p_2 === 'Payed') {
      premios.push(
        <li key="premio_2">
          <strong>Premio 2:</strong> {ticket.premio_2} {ticket.symbol_2}
        </li>
      );
    }

    // Verifica si status_p_3 es "Payed" y muestra symbol_3 y premio_3
    if (ticket.status_p_3 === 'Payed') {
      premios.push(
        <li key="premio_3">
          <strong>Premio 3:</strong> {ticket.premio_3} {ticket.symbol_3}
        </li>
      );
    }

    // Retorna los premios si existen, de lo contrario no muestra nada
    return premios.length > 0 ? <ul>{premios}</ul> : null;
  };

  return (
    <div className="ModalBoletoInv">
      <div className="ModalBoletoInv-content">
        <h3>Detalles del Boleto</h3>
        <p><strong>Jornada:</strong> {ticket.jornada}</p>
        <p><strong>Hash:</strong> {ticket.trx_id.slice(-8)}</p>
       <p><strong>Costo:</strong> {ticket.costo} {ticket.r_symbol}</p>
        <p><strong>Usuario:</strong> {ticket.usuario}</p>
        <p><strong>Fecha:</strong> {new Date(ticket.dia).toLocaleDateString()}</p>
        <p><strong>Estado:</strong> {ticket.status}</p>
        <p><strong>Aciertos:</strong> {ticket.acerto}</p>

        {/* Botón que alterna la visibilidad de las carreras */}
        <button className="ModalBoletoInv-button" onClick={toggleRaces}>
          {showRaces ? "Ocultar Carreras y Caballos" : "Mostrar Carreras y Caballos"}
        </button>

        {/* Mostrar las carreras solo si showRaces es true */}
        {showRaces && (
          <ul>
            {renderRaces()}
          </ul>
        )}

        {/* Mostrar premios si existen */}
        {renderPremios()}

        <button className="ModalBoletoInv-button" onClick={onClose}>Cerrar</button>
      </div>
    </div>
  );
};

export default ModalBoletoIndividual;

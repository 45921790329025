import React, { useState, useEffect } from 'react';

 import './../css/ModalPatroInv-content.css';
 import Icon from './../../../generals/svgiconos/svgiconosenreact.jsx';
 import hivelogo from'./../../../../assets/logoblockhorseracing/logoHive.png';
import logosBHR from'./../../../../assets/logoblockhorseracing/logoBlock.png';
import logosBHRT from'./../../../../assets/logoblockhorseracing/coinBHRT2.png';

 import { get_Torneo} from './../../../generals/servicios/torneo/getTorneo.jsx';
import { obtenerDatosWallet } from './../../../generals/servicios/wallet/getSaldosW.jsx';

import { registrarCaballo } from './../../../generals/servicios/registrarEnTorneo.jsx';

import BotonRegistro from "./../auxiliares/BotonRegistro.jsx";
import RegistroNotificacion from "./../auxiliares/RegistroNotificacion";







const ModalPatrocinarBallo = ({ isOpenMail, toggleOpenMail, horses, isVisibleMail }) => {
  const [caballosHabilitados, setCaballosHabilitados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(localStorage.getItem("user"));
  const [error, setError] = useState(null);
  const [registrados, setRegistrados] = useState(0);
 const [costoRegistro1, setCostoRegistro1] = useState(null);
  const [costoRegistro2, setCostoRegistro2] = useState(null);
  const [costoRegistro3, setCostoRegistro3] = useState(null);
  const [idTorneo, setIdTorneo] = useState(null);
  const [tipo, setTipo] = useState('');
  const [status, setStatus] = useState('');
  const [statusInscripciones, setStatusInscripciones] = useState('');
  const [symbol1Registro, setSymbol1Registro] = useState('');
  const [symbol2Registro, setSymbol2Registro] = useState('');
  const [symbol3Registro, setSymbol3Registro] = useState('');
  const [saldo, setSaldo] = useState(null);
  const [saldoBhrt, setSaldoBhrt] = useState(null);
  const [saldoBhr, setSaldoBhr] = useState(null);
  const [statusRetiro, setStatusRetiro] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [isRegistroExitoso, setIsRegistroExitoso] = useState(false); // Estado para gestionar la confirmación
  const [mensajeRegistro, setMensajeRegistro] = useState("");
  const [tipoMensaje, setTipoMensaje] = useState("");


      

const tipoBallo =horses.tipo;


const { carreras_win,  carreras_third , carreras_second , carreras, implementos_status,
  opcion_entrenamiento,
  Dia_D_Alimento ,trx_nft,IPFS,
  consecutivas_lose,
  consecutivas_win,
  for_shedule ,
  Dia_D_update,
  updatedAt,
  Schedule,
  alimentos,
  alimentos_status,
  entrenamiento_gratis,
  trx_entrenmientos,
  lastTrain,
  last_training,
  Carreras_Utiles,
  Dias_Vida,
  Nacimiento,
  __v,
  name,
  Dia_descanzo,
  lastRace,
  UltimoUpdateValidoCaballos,
   alimentado,
 
   ...restoDePropiedades } = horses;


// const handleRegistro = async (token, costo) => {
//   setLoading(true);
//   setError(null);


// const data=[restoDePropiedades]

//   const datosCaballo = {
//     ...horses,
//     token,
//     costo, // Puedes pasar el tipo de token (BHRT o HIVE) dependiendo de qué botón se presione
//   };

//   try {
//     const result = await registrarCaballo(datosCaballo);
//     if (result.success) {
//       alert('Registro exitoso');
//     } else {
//       setError(result.error);
//     }
//   } catch (error) {
//     setError("Error en el registro, intenta nuevamente");
//   } finally {
//     setLoading(false);
//   }
// };







const handleRegistro = async (token, costo) => {
  setLoading(true);
  setError(null);

  const data = [restoDePropiedades]; // Definir data según las propiedades necesarias

  const datosCaballo = {
    ...horses,
    token,
    costo, // Puedes pasar el tipo de token (BHRT o HIVE) dependiendo de qué botón se presione
  };

  try {
    // Solicitud de confirmación a Keychain
    const confirmacion = await comprobarFirma(user);

    if (confirmacion) {
      // Procede con el registro solo si la confirmación es exitosa
      const result = await registrarCaballo(datosCaballo);
      if (result.success) {
          setMensajeRegistro("Registration complete!");
          setTipoMensaje("success");
         // setIsRegistroExitoso(true);
      } else {
        setError(result.error);
          setMensajeRegistro("Registration rejected by the user");
        setTipoMensaje("error");
      }
    } else {
      setError("Registration rejected by the user.");
    }
  } catch (error) {
    setError("Unexpected error, please try again");
  } finally {
    setLoading(false);
  }
};

// Función para solicitar confirmación en Keychain
const comprobarFirma = async (usuario) => {
  const mensaje ="Approve transaction";
  // Comprueba si Hive Keychain está disponible en el objeto global "window".
  if (!Object.prototype.hasOwnProperty.call(window, "hive_keychain")) {
    console.error("Please install Hive Keychain.");
    return false;
  }

  return new Promise((resolve) => {
    // Solicita la firma utilizando Hive Keychain.
    window.hive_keychain.requestSignBuffer(
      usuario,
      mensaje, // Mensaje que se va a firmar
      "Posting",
      (response) => {
        if (response.success === true) {
          resolve(true); // El usuario pudo firmar el mensaje.
        } else {
          console.error("Error al firmar el mensaje.");
          resolve(false); // El usuario no pudo firmar el mensaje.
        }
      }
    );
  });
};





  const getIconBySymbol = (symbol) => {
    switch (symbol) {
      case 'BHRT':
        return logosBHRT;
      case 'SWAP.HIVE':
        return hivelogo;
        case 'BHR':
        return logosBHR;
      default:
        return hivelogo; // O un ícono por defecto si lo necesitas
    }
  };

  const multiplicadoresRareza = {
  Common: 1,
  Uncommon: 2,
  Rare: 3,
  Spectral:5,
  Epic: 8,
  Mythic: 10,
  Legendary: 12.5,
  XCommon: 1,
  XUncommon: 2,
  XRare: 3,
  XSpectral:5,
  XEpic: 8,
  XMythic: 10,
  XLegendary: 12.5,
};






// Función para calcular el costo basado en la rareza y la puntuación del caballo
const calcularCostoFinal = (costoBase, rareza, token) => {
  let multiplicador = multiplicadoresRareza[rareza] || 1; // Aplicar el multiplicador de rareza

  // Aplicar el multiplicador adicional basado en la animo del caballo
  if (token==="BHRT" || token ==="BHR") {
    multiplicador *= 1; // Si la animo está entre 100 y 51, el costo no cambia (x1)
  } else if (token==="SWAP.HIVE") {
    multiplicador *= 1.5; // Si la animo está entre 50 y 21, el costo se duplica (x2)
  } 

  return costoBase * multiplicador; // Devolver el costo final con el multiplicador aplicado
};






const costoFinal1 = calcularCostoFinal(costoRegistro1, tipoBallo,"BHRT");
const costoFinal2 = calcularCostoFinal(costoRegistro2, tipoBallo,"BHR");
const costoFinal3 = calcularCostoFinal(costoRegistro3, tipoBallo,"SWAP.HIVE");







useEffect(() => {
    if (isVisibleMail && user) {  // Solo ejecutar si el componente está visible y `user` está definido
      const fetchData = async () => {
        try {
          const resultado = await obtenerDatosWallet(user);
          const { saldo, ganancia_a, ganancia_b, ganancia_BHR, status_retiro, tickets } = resultado.data;

          // Desestructurar y mostrar en consola
          console.log("Saldo:", saldo);
          console.log("Ganancia A:", ganancia_a);
          console.log("Ganancia Consuelo:", ganancia_b);
          console.log("Ganancia BHR:", ganancia_BHR);
          console.log("Status Retiro:", status_retiro);
          console.log("Tickets:", tickets);

          // Actualizar los estados
          setSaldo(saldo);
          setSaldoBhrt (ganancia_b);
          setSaldoBhr (ganancia_BHR);
          setStatusRetiro(status_retiro);
          setTickets(tickets);

          setShowModal(true); // Mostrar el modal con los resultados
        } catch (error) {
          setError(error.message);
          console.error(error);
        }
      };

      fetchData();
    }
  }, [isVisibleMail, horses.equineId]);


useEffect(() => {

    if (isVisibleMail && user) {  // Solo ejecutar si el componente está visible y `user` está definido
      // alert("Mail Creado")
      const fetchData = async () => {
        try {
          const torneo_Abierto = await get_Torneo();

          const { Premio_Fase_1, Premio_Fase_2, Premio_Fase_3, Premio_Fase_4, Registrados,costo_registro_1,costo_registro_2,costo_registro_3,id_Torneo,status,status_inscriciones,symbol_1_registro,symbol_2_registro,symbol_3_registro,tipo } = torneo_Abierto.data.body[0];
 
    
          // Desestructurar y mostrar en consola
          // console.log("BUQUEDATORNEO", torneo_Abierto.data.body);
          setRegistrados(Registrados);
          setCostoRegistro1(costo_registro_1);
          setCostoRegistro2(costo_registro_2);
          setCostoRegistro3(costo_registro_3);
          setIdTorneo(id_Torneo);
          setStatus(status);
          setStatusInscripciones(status_inscriciones);
          setSymbol1Registro(symbol_1_registro);
          setSymbol2Registro(symbol_2_registro);
          setSymbol3Registro(symbol_3_registro);
          setTipo(tipo);
          // Mostrar el modal con los resultados
        } catch (error) {
          setError(error.message);
          console.error(error);
        }
      };

      fetchData();
    }
  }, [ isVisibleMail,horses.equineId])
  // [isVisibleMail, datos.id]); // Ejecutar solo cuando el componente sea visible o `user` cambie


  useEffect(() => {
    // Obtener el nombre del usuario desde el localStorage
    const usuario = localStorage.getItem('usuario');

    if (usuario) {
      // Filtrar los caballos habilitados por el usuario actual
      const caballosFiltrados = registrados.filter(
        (caballo) => caballo.habilitador === usuario
      );

      // Actualizar el estado con los caballos filtrados
      setCaballosHabilitados(caballosFiltrados);
    }
  }, [registrados]);



// Función que devuelve el saldo basado en el símbolo
const getSaldoBySymbol = (symbol) => {
  switch (symbol) {
    case 'BHRT':
      return saldoBhrt;
    case 'BHR':
      return saldoBhr;
    default:
      return saldo; // Saldo general
  }
};
const isSaldoInsuficiente = (symbol, costo) => {
  const saldoActual = getSaldoBySymbol(symbol);
  return saldoActual < costo;
};





return (
  <div className="ModalPatroInv">
    <div className="ModalPatroInv-content">
      <ul>
        <li key={horses.equineId} className="horse-item">
          <div className="horse-header">
            <span className="horse-id">ID: {horses.equineId}</span> {horses.tipo}
          </div>
          <span className="horse-type">Owner: {horses.account}</span>
        </li>
      </ul>

      <div className="letter-text">
        <p>Torneo: {idTorneo}</p>
      </div>

    {/* Mostrar notificación si hay un mensaje */}
        {mensajeRegistro ? (
          <RegistroNotificacion mensaje={mensajeRegistro} tipo={tipoMensaje} />
        ) : (
          <>
            {/* Botones de Registro, visibles solo si no hay mensaje */}
            <BotonRegistro
              symbol={symbol1Registro}
              costoFinal={costoFinal1}
              saldo={saldoBhrt}
              handleRegistro={handleRegistro}
              isSaldoInsuficiente={isSaldoInsuficiente}
              getIconBySymbol={getIconBySymbol}
            />
            <BotonRegistro
              symbol={symbol2Registro}
              costoFinal={costoFinal2}
              saldo={saldoBhr}
              handleRegistro={handleRegistro}
              isSaldoInsuficiente={isSaldoInsuficiente}
              getIconBySymbol={getIconBySymbol}
            />
            <BotonRegistro
              symbol={symbol3Registro}
              costoFinal={costoFinal3}
              saldo={saldo}
              handleRegistro={handleRegistro}
              isSaldoInsuficiente={isSaldoInsuficiente}
              getIconBySymbol={getIconBySymbol}
            />
          </>
        )}


     
      <button className="ModalPatroInv-closeButton" onClick={toggleOpenMail}>
  &times; {/* Esto renderiza una "X" elegante */}
</button>

    </div>
  </div>
);
}
export default ModalPatrocinarBallo;
 
import React from 'react';
import "./../../Css/Auxiliar/RaceItem.css";

const RaceItem = ({ race, apuestasUser, openModalInf, calculateTimeRemaining, getStatusColor }) => {
  const raceId = race.raceid;
  const isRaceInUser = Object.values(apuestasUser).some((item) => item.race === raceId);

  return (
    <div
      onClick={() => openModalInf(race.raceid, race.name)}
      className={`race-item-card ${isRaceInUser ? 'race-highlight' : 'race-baselight'}`}
    >
      <div className="race-header">
        <h2 className="raceid">{race.raceid}  <span className="raceid-symbol" style={{ color: isRaceInUser ? 'darkgoldenrod' : 'inherit' }}>
              {isRaceInUser ? '$' : ''}
            </span></h2>
        <span className="tiempo-faltante" style={{ color: getStatusColor(race.status_superior) }}>
          {calculateTimeRemaining(race.tiempodesalida)}
        </span>
         <h2>
            {race.registrados.length}/{race.maxParticipantes}
           
          </h2>
      </div>   <div className="race-participants">
         
         
        </div>

      <div className="race-body">
        <span className="name">{race.name}</span>
     
      </div>

      <div className="race-footer">
        {race.status_superior === 'abierta' ? (
          <div className="circle green"></div>
        ) : race.status_superior === 'en_proceso' ? (
          <div className="circle red"></div>
        ) : (
          <div className="semaforo-circle black"></div>
        )}
         <span className="status-text">
            {race.status_superior === 'abierta' ? 'Open' : race.status_superior === 'en_proceso' ? 'In Process' : race.status_superior}
          </span>
      </div>
    </div>
  );
};

export default RaceItem;

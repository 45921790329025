import React, { useState, useEffect } from "react";
import axios from 'axios';
import Loader from "./../generals/loders/Loader.jsx";
import CaballosEnWallet from './components/auxiliar/CaballosEnWallet.jsx';
import { linkApp } from './../generals/configuracion/variablesPublicas.jsx';
import "./components/cardballos.css";

export default function Ballos() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [BallosEnBase, setBallosEnBase] = useState([]);

  function cargarEquinoData(usuario) {
    const url = `${linkApp}/equino`;
    const bodyData = { account: usuario };

console.log("bodyData",bodyData)
    axios.post(url, bodyData)
      .then(response => {
        let datos = response.data.body;
        console.log("Respuesta desde la app:", response.data.body);

        if (datos.length === 0) {
          // let Ballo = {
          //   id: "000",
          //   identidad: "???",
          //   tipo: "000",
          //   generacion: "000",
          //   sexo: "000",
          //   salud: "000",
          //   Velocidad: "000",
          //   addVelocidad: "000",
          //   Resistencia: "000",
          //   addResistencia: "000",
          //   Agilidad: "000",
          //   nacimiento: "000",
          //   diasdevida: "000",
          //   carrerasUtiles: "000",
          //   status: "000",
          //   name: "Parece que aun no tienes un caballo",
          //   animo: "000",
          //   ganadasTotal: "000",
          //   carrerasCorridas: "000",
          //   GanadasConsecutivas: "000",
          //   diaDescanzo: "000",
          
          // };
          // setBallosEnBase((Ballos) => [...Ballos, Ballo]);
          setIsLoaded(true)
        } else {
          if (datos.length > 0) {
            console.log("WESSI ESTO EXISTE");
            const ownerBallos = datos.map((item, index) => {
              let identidad = item.equineId;
              let Minteo = identidad;
              console.log("IPFS", Minteo);
              return {
                id: item.equineId,
                identidad: item.equineId,
                tipo: item.tipo,
                Carreras_Utiles: item.Carreras_Utiles,
                Dias_Vida: item.Dias_Vida,
                IPFS: item.IPFS,
                Nacimiento: item.Nacimiento,
                Schedule: item.Schedule,
                account: item.account,
                agilidad: item.agilidad,
                alimentos: item.alimentos,
                alimentos_ptos: item.alimentos_ptos,
                alimentos_status: item.alimentos_status,
                animo: item.animo,
                carreras: item.carreras,
                carreras_second: item.carreras_second,
                carreras_third: item.carreras_third,
                carreras_win: item.carreras_win,
                consecutivas_lose: item.consecutivas_lose,
                consecutivas_win: item.consecutivas_win,
                entrenamiento_gratis: item.entrenamiento_gratis,
                equineId: item.equineId,
                estado: item.estado,
                for_shedule: item.for_shedule,
                generacion: item.generacion,
                implementos_ptos: item.implementos_ptos,
                implementos_status: item.implementos_status,
                lastRace: item.lastRace,
                name: item.nameBhr,
                opcion_entrenamiento: item.opcion_entrenamiento,
                resistencia: item.resistencia,
                resistencia_add: item.resistencia_add,
                salud: item.salud,
                schedule: item.schedule,
                sexo: item.sexo,
                status: item.status,
                status_oficial: item.status_oficial,

                tipo: item.tipo,
                velocidad: item.velocidad,
                velocidad_add: item.velocidad_add
              };
            });
            setBallosEnBase((prevBallos) => [...prevBallos, ...ownerBallos]);
          }
     
        }
        console.log("BALLERO BALLOS", BallosEnBase);
      })
      .catch(error => {
        console.error("Error en la solicitud:", error);
      });
       setIsLoaded(true)
  }

  useEffect(() => {
    const account = localStorage.getItem("user");
    if (account) {
      cargarEquinoData(account);
    }
  }, []);





  return (
    <div className="wrappBallos">
      <div className="container-dash">
        {!isLoaded ? (
          <Loader />
        ) : (
          BallosEnBase.map((item, index) => (

           
          <CaballosEnWallet  
           key={item.equineId}  

id={ item.equineId}
identidad={ item.equineId}
tipo={item.tipo}
Carreras_Utiles={ item.Carreras_Utiles}
Dias_Vida={ item.Dias_Vida} 
IPFS={ item.IPFS }
Nacimiento={item.Nacimiento}
Schedule={ item.Schedule} 
account={ item.account}
agilidad={ item.agilidad}
alimentos={ item.alimentos}
alimentos_ptos={ item.alimentos_ptos}
alimentos_status={ item.alimentos_status}
animo={ item.animo }
carreras={ item.carreras} 
carreras_second={ item.carreras_second}
carreras_third={ item.carreras_third }
carreras_win={ item.carreras_win }
consecutivas_lose
={ item.consecutivas_lose} 
consecutivas_win
={ item.consecutivas_win}
entrenamiento_gratis
={ item.entrenamiento_gratis}
equineId
={ item.equineId }
estado
={ item.estado }
for_shedule
={ item.for_shedule }
generacion
={ item.generacion }
implementos_ptos
={ item.implementos_ptos}
implementos_status
={ item.implementos_status} 
lastRace
={ item.lastRace}
name
={ item.name }
opcion_entrenamiento
={ item.opcion_entrenamiento} 
resistencia
={ item.resistencia }
resistencia_add
={ item.resistencia_add} 
salud
={ item.salud }
schedule
={ item.schedule} 
sexo
={ item.sexo }
status
={ item.status} 
status_oficial
={ item.status_oficial} 
tipo
={ item.tipo }

velocidad
={ item.velocidad} 
velocidad_add
={ item.velocidad_add}
 
           />
         








          ))
        )}
      </div>
    </div>
  );
}























































































































































// import React, { useState, useEffect } from "react";
// import axios from 'axios';

// import './components/cardballos.css'
// import reloj from'./components/auxiliar/reloj.jsx'

// import iconNegocios from'./../../assets/home-broker-dealer-icon.svg'
// import iconCarrera from'./../../assets/horse-riding-icon.svg'
// import infoballo from'./../../assets/horseshoe-icon.svg'
// import ModalBallo from"./components/models/InfoballoModal.jsx"
// import ModalRace from"./components/models/RaceBalloModal.jsx"
// import ModalNegoti from"./components/models/NegotiationModal.jsx"
// import  "./components/models/infoballoModal.css"

// import Loader from"./../generals/loders/Loader.jsx"
// //Impor de iconos SVG para infoballo
// import salucita from"./../../assets/components/svg/salud/heart-svgrepo-com.svg"
// import temperatura from"./../../assets/components/svg/temperamentp/calmness-svgrepo-com.svg"
// import { useTranslation } from 'react-i18next';
// import EquineImage from './EquineImage';



//  //key, Imagen, name,sex,salud,resiste,addResi,Resistencia,addResistencia 


// let initialValue=false


// function Ballo({...props}) {
    
//     const [isOpenInf, setIsOpenInf] = useState(initialValue);
//    const [selectedId, setSelectedId] = useState(null);
//      const { t } = useTranslation();
   

// //   console.log("propsporpsoppsopspops",props)

//   const openModalInf = (id) => { setIsOpenInf(true);
//     setSelectedId(id);

//    // console.log("ABIERTO CABALLO EN MODAL INFO",id)
//   }

//   const closeModalInf = () => setIsOpenInf(false);



//   const [isOpen, setIsOpen] = useState(false);

//   const openModal = () => setIsOpen(true);

//   const closeModal = () => setIsOpen(false);


//    const [isOpenNeg, setIsOpenNeg] = useState(initialValue);

//   const openModalNeg = () => setIsOpenNeg(true);

//   const closeModalNeg = () => setIsOpenNeg(false);



// const imageUrl = props.Imagen;
// const fallbackUrl = "http://localhost:4000/image/equinos/000.png";



//    return (
//     <div className="card">
//   {props.identidad == "???" || props === [] ? (
//   <>
//   <div className="card">
//     <div className="box">
//       <div className="content">
//         <h2>{props.Key}AQUI VOY</h2>
//          <div className="cajo" style={{backgroundImage: `url("https://ipfs.io/ipfs/bafybeibydhcgj6ibcmdx2mo3vkktlo4chxuhyec3gqah3ojkxqvw5xh7ye")`}}>
//           <img src={imageUrl} alt="img" className="cajas" />
//          </div>
//         <p><h4>{t('Caballos.parece')}</h4></p>

     
//            <a href={`/`}
//       style={{
//         color: '#FF0000',
//         textDecoration: 'none',

//       }}
//     >
      
     
//       <span className="arrow arrow1">{'>'}</span>
//        <span className="arrow arrow2">{'>'}</span>
//         <span className="arrow arrow3">{'> '} &nbsp; </span>
//        <span className="arrow arrow">{t('Caballos.ve_por_uno')}</span> 
//          <span className="arrow arrow1"> &nbsp;{' <'}</span>
//        <span className="arrow arrow2">{'<'}</span>
//         <span className="arrow arrow3">{'<'}</span>
//     </a>
      
//     </div>
//   </div>
//  </div>



// </>
  
// ) : 


//     <div className="box">
//       <div className="content">
//         <h2>{props.Key}</h2>



//     <div className="caja" style={{backgroundImage: `url("https://ipfs.io/ipfs/bafybeibydhcgj6ibcmdx2mo3vkktlo4chxuhyec3gqah3ojkxqvw5xh7ye")`}}>
     
//      <img src={imageUrl} alt="imagen" onError={(e) => { e.target.onerror = null; e.target.src = fallbackUrl; }} style={{ display: 'none' }} />
//       <EquineImage
//           mainImageSrc={`/image/equinos/${props.identidad}.png`}
//           backupImageSrc={`/image/equinos/000.png`}
//           isHovered={false}
        
//         />
//     <img src={props.Imagen} alt="img" className="cajas" /> */}
//       </div>
      
//         <p>{props.name}</p>
//         <p><spam>Mint : </spam>{props.identidad}   </p>
//          <h2>{props.key}</h2>
    
    
//       {/*   <img src={iconNegocios} alt={props.name} className="icono" onClick={openModalNeg} /> 
//         <img src={iconNegocios} alt={props.name} className="icono-caballos" title="Negotiate with your NFT" onClick={openModalNeg}/>
//        */}
//         <img src={iconCarrera} alt={props.name} className="icono-caballos" onClick={openModal} title="Find races for your horse"/>
      
      
  
//         <img src={infoballo} alt={props.name} className="icono-caballos"  onClick={() => openModalInf(props.identidad)} title="NFT information"/>

      
  
//       </div>
//     </div> }
//          {isOpenInf && (  <ModalBallo isOpen={isOpenInf} closeModalInf={closeModalInf}   propsi={props}  selectedId={selectedId}    onClose={() => setIsOpenInf(false)}   >

//          <button className="modal-close" onClick={closeModalInf}>
//         X
//         </button>


//     </ModalBallo> )}



//    <ModalRace isOpen={isOpen} closeModal={closeModal}  propsi={props}>
      
//     </ModalRace>

   
//     <ModalNegoti isOpenNeg={isOpenNeg} closeModalNeg={closeModalNeg}  propsi={props}>
   
      
//     </ModalNegoti>
//     </div>

//   );
// }




    


// export default function AjaxHooks() {
//   const [Ballos, setBallos] = useState([]);
//  const [TimeActual, setTimeActual] = useState(null);
// const [isLoaded, setisLoaded] = useState(false);


// useEffect(() => {


//   //   axios.get('http://localhost:4000/time')
//   // .then(response => setTimeActual(response.data.body[0].updatedAt))





    
//  const getBallos = async (url) => {

//       let config = {
//       headers: {
//          'Content-Type': 'application/json',
//          'Access-Control-Allow-Origin': '*',
//       },
//     };

//     let query={}
//       query.account = localStorage.getItem("user");
    


//     let body = JSON.stringify([
//       {
//         method: "find",
//         jsonrpc: "2.0",
//         params: {
//           contract: 'nft',
//           table: "BHRIGAinstances",
//           query: query,
//           limit: 50,
//           offset: 0,
//           indexes: ["properties.Properties"],
//         },
//         id: 1,
//       },
//     ]);

//      await axios.post(url, body, config).then((data) => {
//         //console.log("DATES",data)
//       data =data.data[0].result;
   


// if(data.length===0){
// //console.log("length  DATATATA",data.length)
// let Ballo = {
//           id: "000",
//           identidad:"???",
//           tipo: "000",
//           generacion: "000",
//           sexo: "000",
//           salud: "000",
//           Velocidad: "000",
//           addVelocidad: "000",
//           Resistencia: "000",
//           addResistencia: "000",
//           Agilidad: "000",
//           nacimiento: "000",
//           diasdevida: "000",
//           carrerasUtiles: "000",
//           status: "000",
//           name: "Parece que aun no tienes un caballo",
//           animo: "000",
//           ganadasTotal: "000",
//           carrerasCorridas: "000",
//           GanadasConsecutivas:"000",
//           diaDescanzo: "000",
//           imagen: "/image/ballos/ballorandom1.png",
        
//         };

//         setBallos((Ballos) => [...Ballos, Ballo]);
//       };
    

// data.forEach( (data,indexes) => {
// // ACA VAN TODAS LAS PROPIEDADES DE UN BALLO O GUA

// let Rarity=data.properties.Properties
// //Attributes:
// let identidad=data._id;
// let Generacion=data.properties.Attributes.substr(0,4)
// // console.log("Generacion", Generacion);
// let Minteo=identidad 
// // console.log("Minteo", Minteo);
// let Sexo=data.properties.Attributes.substr(5,1)
// // console.log("Sexo", Sexo);
// let Salud=data.properties.Attributes.substr(7,3)
// // console.log("Salud", Salud);
// let Velocidad=data.properties.Attributes.substr(11,3)
// // console.log("Velocidad", Velocidad);

// let Resistencia=data.properties.Attributes.substr(15,3)
// // console.log("Resistencia", Resistencia);

// let Agilidad=data.properties.Attributes.substr(19,3)
// // console.log("Agilidad", Agilidad);
// let Nacimiento=data.properties.Attributes.substr(23,16)
// // console.log("Nacimiento", Nacimiento);
// let Diasdevida=data.properties.Attributes.substr(40,4)
// // console.log("Diasdevida", Diasdevida);
// let CarrerasUtiles=data.properties.Attributes.substr(45,4)
// // console.log("CarrerasUtiles", CarrerasUtiles);
// let STATUS=data.properties.Attributes.substr(50,1)
// // console.log("STATUS", STATUS);
// let NAME=data.properties.Attributes.substr(52,25)
// // console.log("NAME", NAME);

// //Details:
//   //console.log("Added Added Added AddedAdded  Added Added Added Added Added Added Added");


// let Animo=data.properties.Details.substr(0,3);
// // console.log("animo", Animo);
// let AddVelocidad=data.properties.Details.substr(4,3)
// // console.log("AddVelocidad", AddVelocidad);
// let AddResistencia=data.properties.Details.substr(8,3)
// // console.log("AddResistencia", AddResistencia);

// let GanadasTotal=data.properties.Details.substr(12,3);
// // console.log("GanadasTotal", GanadasTotal);
// let CarrerasCorrida=data.properties.Details.substr(16,4);
// // console.log("CarrerasCorrida", CarrerasCorrida);
// let GanadasConsecutivas=data.properties.Details.substr(21,2);
// let DiaDescanzo=data.properties.Details.substr(23,16);
// // console.log("DiaDescanzo", DiaDescanzo);
// let IPFS="https://ipfs.io/ipfs/" + data.properties.Details.substr(38,65);
//  // console.log("IPFS", IPFS);









//   let Ballo = {
//           id: Minteo,
//           identidad:identidad,
//           tipo: Rarity,
//           generacion: Generacion,
//           sexo: Sexo,
//           salud: Salud,
//           Velocidad: Velocidad,
//           addVelocidad: AddVelocidad,
//           Resistencia: Resistencia,
//           addResistencia: AddResistencia,
//           Agilidad: Agilidad,
//           nacimiento: Nacimiento,
//           diasdevida: Diasdevida,
//           carrerasUtiles: CarrerasUtiles,
//           status: STATUS,
//           name: NAME,
//           animo: Animo,
//           ganadasTotal: GanadasTotal,
//           carrerasCorridas: CarrerasCorrida,
//           GanadasConsecutivas:GanadasConsecutivas,
//           diaDescanzo: DiaDescanzo,
//           imagen: IPFS,
        
//         };

//         setBallos((Ballos) => [...Ballos, Ballo]);
//       });
    

//       }).catch((err)=>{  
//            console.log("error fetching",err);
//                 } 
//          ).finally(()=>{

//           //console.log("se ejecuta finally");
//           setisLoaded(true)
//       })
    
//    };
//     getBallos("https://engine.rishipanthee.com/contracts");

//   },[]);

// //otros rcps
// //https://api.hive-engine.com/rpc/contracts
// //https://engine.rishipanthee.com/contracts



//   return (
//     <>
//              <div className="wrapp">
//       <div className="container">

//       {((Ballos.length === 0) &&(isLoaded===false)) ? (
//         <Loader/>

//       ) : (
       

//         Ballos.map((el,index) => (
           
//           <Ballo  key={el.index}  tipo= {el.tipo} identidad={el.identidad} minteo={el.id}genera={el.generacion} sex={el.sexo} salud={el.salud} 
//           veloz={el.Velocidad}  addVeloz={el.addVelocidad} 
//           Resistencia={el.Resistencia} addResi={el.addResistencia}  
//           Agilidad={el.Agilidad} nacimiento={el.nacimiento} 
//           diasdevida={el.diasdevida} carrerasUtiles={el.carrerasUtiles} 
//           status={el.status} name={el.name} animo={el.animo} 
//           ganadasTotal={el.ganadasTotal} 
//           carrerasTotals={el.carrerasCorridas} ganadasCon={el.GanadasConsecutivas} diaDescanzo={el.diaDescanzo} Imagen={el.imagen} TimeActual={TimeActual}  />
         

      
         



//         ))
         
//       )}
//       </div></div>
//     </>
//   );
// }


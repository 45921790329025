import React, { useState, useEffect } from 'react';
import './../css/modal/ModalMail.css'; // Asegúrate de tener el CSS en el mismo directorio o ajusta la ruta
import hivelogo from'../../../../../../../assets/logoblockhorseracing/logoHive.png';
import logosBHR from'../../../../../../../assets/logoblockhorseracing/logoBlock.png';
import logosBHRT from'../../../../../../../assets/logoblockhorseracing/coinBHRT2.png';

import { obtenerDatosWallet } from './../../../../../../generals/servicios/wallet/getSaldosW.jsx';
import { get_Torneo} from './../../../../../../generals/servicios/torneo/getTorneo.jsx';
import { registrarCaballo } from './../../../../../../generals/servicios/registrarEnTorneo.jsx';


const ModalMail = ({ isOpenMail, toggleOpenMail, datos, isVisibleMail }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(localStorage.getItem("user"));
  const [showModal, setShowModal] = useState(false);

  const [saldo, setSaldo] = useState(null);
  const [saldoBhrt, setSaldoBhrt] = useState(null);
  const [saldoBhr, setSaldoBhr] = useState(null);
  const [statusRetiro, setStatusRetiro] = useState(null);
  const [tickets, setTickets] = useState(null);

   const [premioFase1, setPremioFase1] = useState(null);
  const [premioFase2, setPremioFase2] = useState(null);
  const [premioFase3, setPremioFase3] = useState(null);
  const [premioFase4, setPremioFase4] = useState(null);
  const [registrados, setRegistrados] = useState(0);
  const [costoRegistro1, setCostoRegistro1] = useState(null);
  const [costoRegistro2, setCostoRegistro2] = useState(null);
  const [costoRegistro3, setCostoRegistro3] = useState(null);
  const [idTorneo, setIdTorneo] = useState(null);
  const [status, setStatus] = useState('');
  const [statusInscripciones, setStatusInscripciones] = useState('');
  const [symbol1Registro, setSymbol1Registro] = useState('');
  const [symbol2Registro, setSymbol2Registro] = useState('');
  const [symbol3Registro, setSymbol3Registro] = useState('');
  const [tipo, setTipo] = useState('');
  const [botonDeshabilitado1, setBotonDeshabilitado1] = useState(false);
  const [botonDeshabilitado2, setBotonDeshabilitado2] = useState(false);
  const [botonDeshabilitado3, setBotonDeshabilitado3] = useState(false);


console.log("DATOS",datos.id)
console.log("isVisibleMail",isVisibleMail)

const tipoBallo =datos.tipo;
const animo=datos.animo
console.log("DATOS TIPO",tipoBallo)


let mail=[]
 mail.title='Te invitamos a registrar tu equino para la próxima temporada oficial';
 
 mail.body= '<ol style="padding-left: 1px; font-size: 14px; color: #1E3A8A; line-height: 1.25; margin-bottom: 16px; max-width: 280px; word-wrap: break-word;"><p>Cada torneo incluye</p> <p>tres emocionantes fases:</p><li style="margin-bottom: 1px;">Clasificatoria.</li><li style="margin-bottom: 1px;">Semifinales.</li><li style="margin-bottom: 1px;">Gran Final.</li></ol>'
 
 mail.footer='Este registro asegura la participación de tu equino durante toda la temporada. Ten en cuenta que si no completas el registro a tiempo, se abrirá una inscripción pública y tu equino podría ser auspiciado por otra cuenta, que se llevará los beneficios de la participación. ¡Asegura tu lugar y buena suerte!'

  const getIconBySymbol = (symbol) => {
    switch (symbol) {
      case 'BHRT':
        return logosBHRT;
      case 'SWAP.HIVE':
        return hivelogo;
        case 'BHR':
        return logosBHR;
      default:
        return hivelogo; // O un ícono por defecto si lo necesitas
    }
  };

  const multiplicadoresRareza = {
  Common: 1,
  Uncommon: 2,
  Rare: 3,
  Spectral:5,
  Epic: 8,
  Mythic: 10,
  Legendary: 12.5,
  XCommon: 1,
  XUncommon: 2,
  XRare: 3,
  XSpectral:5,
  XEpic: 8,
  XMythic: 10,
  XLegendary: 12.5,
};





// Función para calcular el costo basado en la rareza y la puntuación del caballo
const calcularCostoFinal = (costoBase, rareza, animo) => {
  let multiplicador = multiplicadoresRareza[rareza] || 1; // Aplicar el multiplicador de rareza

  // Aplicar el multiplicador adicional basado en la animo del caballo
  if (animo <= 100 && animo > 50) {
    multiplicador *= 1; // Si la animo está entre 100 y 51, el costo no cambia (x1)
  } else if (animo <= 50 && animo > 20) {
    multiplicador *= 2; // Si la animo está entre 50 y 21, el costo se duplica (x2)
  } else if (animo <= 20 && animo > 0) {
    multiplicador *= 3; // Si la animo está entre 20 y 1, el costo se triplica (x3)
  } else if (animo <= 0) {
    multiplicador *= 4; // Si la animo es 0 o negativa, el costo se cuadruplica (x4)
  }

  return costoBase * multiplicador; // Devolver el costo final con el multiplicador aplicado
};



// Calcular los costos finales de inscripción según la puntuación del caballo


const costoFinal1 = calcularCostoFinal(costoRegistro1, tipoBallo,animo);
const costoFinal2 = calcularCostoFinal(costoRegistro2, tipoBallo,animo);
const costoFinal3 = calcularCostoFinal(costoRegistro3, tipoBallo,animo);


useEffect(() => {
    if (isVisibleMail && user) {  // Solo ejecutar si el componente está visible y `user` está definido
      const fetchData = async () => {
        try {
          const resultado = await obtenerDatosWallet(user);
          const { saldo, ganancia_a, ganancia_b, ganancia_BHR, status_retiro, tickets } = resultado.data;

          // Desestructurar y mostrar en consola
          console.log("Saldo:", saldo);
          console.log("Ganancia A:", ganancia_a);
          console.log("Ganancia Consuelo:", ganancia_b);
          console.log("Ganancia BHR:", ganancia_BHR);
          console.log("Status Retiro:", status_retiro);
          console.log("Tickets:", tickets);

          // Actualizar los estados
          setSaldo(saldo);
          setSaldoBhrt (ganancia_b);
          setSaldoBhr (ganancia_BHR);
          setStatusRetiro(status_retiro);
          setTickets(tickets);

          setShowModal(true); // Mostrar el modal con los resultados
        } catch (error) {
          setError(error.message);
          console.error(error);
        }
      };

      fetchData();
    }
  }, [isVisibleMail, datos.id]); // Ejecutar solo cuando el componente sea visible o `user` cambie



useEffect(() => {
    if (isVisibleMail && user) {  // Solo ejecutar si el componente está visible y `user` está definido
      const fetchData = async () => {
        try {
          const torneo_Abierto = await get_Torneo();
          const { Premio_Fase_1, Premio_Fase_2, Premio_Fase_3, Premio_Fase_4, Registrados,costo_registro_1,costo_registro_2,costo_registro_3,id_Torneo,status,status_inscriciones,symbol_1_registro,symbol_2_registro,symbol_3_registro,tipo } = torneo_Abierto.data.body[0];
 
    
          // Desestructurar y mostrar en consola
          console.log("BUQUEDATORNEO", torneo_Abierto.data.body);
          setPremioFase1(Premio_Fase_1);
          setPremioFase2(Premio_Fase_2);
          setPremioFase3(Premio_Fase_3);
          setPremioFase4(Premio_Fase_4);
          setRegistrados(Registrados);
          setCostoRegistro1(costo_registro_1);
          setCostoRegistro2(costo_registro_2);
          setCostoRegistro3(costo_registro_3);
          setIdTorneo(id_Torneo);
          setStatus(status);
          setStatusInscripciones(status_inscriciones);
          setSymbol1Registro(symbol_1_registro);
          setSymbol2Registro(symbol_2_registro);
          setSymbol3Registro(symbol_3_registro);
          setTipo(tipo);
 // Mostrar el modal con los resultados
        } catch (error) {
          setError(error.message);
          console.error(error);
        }
      };

      fetchData();
    }
  }, [isVisibleMail, datos.id]); // Ejecutar solo cuando el componente sea visible o `user` cambie



  // Función para cerrar el modal
  const closeModal = () => {
    toggleOpenMail(); // Utiliza la función del padre para alternar el estado
  };

  // Función para manejar el registro
  const handleRegistro = async (token,costo) => {
    setLoading(true);
    setError(null);
    
    const datosCaballo = {
      ...datos,
      token,
      costo, // Puedes pasar el tipo de token (BHRT o HIVE) dependiendo de qué botón se presione
    };

    const result = await registrarCaballo(datosCaballo);
    
    setLoading(false);

    if (result.success) {
      alert('Registro exitoso');
    } else {
      setError(result.error);
    }
  };



  useEffect(() => {
  // Comparar los saldos con los costos
  setBotonDeshabilitado1(saldoBhrt < costoFinal1);
  setBotonDeshabilitado2(saldoBhr < costoFinal3);
  setBotonDeshabilitado3(saldo < costoFinal2);
}, [saldo, saldoBhrt, saldoBhr, costoFinal1, costoFinal2, costoFinal3]);







// Función que devuelve el saldo basado en el símbolo
const getSaldoBySymbol = (symbol) => {
  switch (symbol) {
    case 'BHRT':
      return saldoBhrt;
    case 'HIVE':
      return saldoBhr;
    default:
      return saldo; // Saldo general
  }
};

// Función que devuelve si el botón debe estar deshabilitado
const isSaldoInsuficiente = (symbol, costo) => {
  const saldoActual = getSaldoBySymbol(symbol);
  return saldoActual < costo;
};




  return (
    <>
      {/* Modal */}
      {isOpenMail && (
        <div className="modalMail-overlay" onClick={closeModal}>
          <div className="modalMail-content" onClick={(e) => e.stopPropagation()}>

            <div className="modalMail-header">
              <button className="close-button" onClick={closeModal}>✖️</button>
            </div>
            <div className="modalMail-body">
              <div className="letter-container">
               <p className="letter-text">{mail.title}</p>
                   <div dangerouslySetInnerHTML={{ __html: mail.body }} />
               <p className="letter-text">{mail.footer}</p>
                   
                <div className="sello"></div>

                {loading ? (
                  <p>Registrando...</p>
                ) : (
                  <>
    {/* Botón para el primer registro */}
{/* Botón para el primer registro */}
<div className="letter-text" >
<p className="letter-text">Torneo: {idTorneo}</p>
</div>
<button
  className="action-button"
  onClick={() => handleRegistro(symbol1Registro, costoFinal1.toFixed(3))}
  disabled={isSaldoInsuficiente(symbol1Registro, costoFinal1)} // Verificar dinámicamente
>
  <>
    <h2>{isSaldoInsuficiente(symbol1Registro, costoFinal1) ? 'Saldo Insuficiente' : 'Registrar'}</h2>
    <p>{symbol1Registro} - {costoFinal1.toFixed(3)}</p> {/* Mostrar símbolo y costo */}
    <img
      src={getIconBySymbol(symbol1Registro)}
      alt={`${symbol1Registro} Token`}
      className="coin"
    />
  </>
</button>

{/* Botón para el segundo registro */}
<button
  className="action-button"
  onClick={() => handleRegistro(symbol2Registro, costoFinal2.toFixed(3))}
  disabled={isSaldoInsuficiente(symbol2Registro, costoFinal2)} // Verificar dinámicamente
>
  <>
    <h2>{isSaldoInsuficiente(symbol2Registro, costoFinal2) ? 'Saldo Insuficiente' : 'Registrar'}</h2>
    <p>{symbol2Registro} - {costoFinal2.toFixed(3)}</p> {/* Mostrar símbolo y costo */}
    <img
      src={getIconBySymbol(symbol2Registro)}
      alt={`${symbol2Registro} Token`}
      className="coin"
    />
  </>
</button>

{/* Botón para el tercer registro */}
<button
  className="action-button"
  onClick={() => handleRegistro(symbol3Registro, costoFinal3.toFixed(3))}
  disabled={isSaldoInsuficiente(symbol3Registro, costoFinal3)} // Verificar dinámicamente
>
  <>
    <h2>{isSaldoInsuficiente(symbol3Registro, costoFinal3) ? 'Saldo Insuficiente' : 'Registrar'}</h2>
    <p>{symbol3Registro} - {costoFinal3.toFixed(3)}</p> {/* Mostrar símbolo y costo */}
    <img
      src={getIconBySymbol(symbol3Registro)}
      alt={`${symbol3Registro} Token`}
      className="coin"
    />
  </>
</button>

                  </>
                )}

                {error && <p className="error-message">{error}</p>}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalMail;

import React, { useState, useEffect } from 'react';
import { linkApp } from './../../../generals/configuracion/variablesPublicas.jsx';
import './racesin.css';
import './racesin2.css';
import Loader from './../../../generals/loders/Loader.jsx';
import ModalinFoRace from './components/modal/ModalinforRace.jsx';
import ModalSellarBoleto from './components/modalboleto/ModalSellarBoleto.jsx';
import ModalPremiosJ from'./components/modalPremiosJornada/ModalPremiosJ.jsx'
import RaceItem from './components/Auxiliares/renderizar/RaceItem';


import { useTranslation } from 'react-i18next';

const RacesComponent = () => {
  const [isOpenInf, setIsOpenInf] = useState(false);
  const [isOpenPremiosJ, setIsOpenPremiosJ] = useState(false);

  const [races, setRaces] = useState([]);
  const [racesType, setRacesType] = useState("Oficial");
  const [errorMessage, setErrorMessage] = useState('');


  const [loading, setLoading] = useState(true);
  const [apuestasUser, setApuestasUser] = useState({});
  const [selectedRace, setSelectedRace] = useState({ raceid: '', name: '' });
  const [filterByDate, setFilterByDate] = useState(true); // Estado para alternar entre filtrar por fecha y filtrar por ID de carrera
  const [filterRacetype, setFilterRacetype] = useState(true); // Estado para alternar entre filtrar por fecha y filtrar por ID de carrera
  // Estado para controlar la apertura del modal de selección de caballos
  const [ModalSellarBtoOpen, setModalSellarBtoOpen] = useState(false);
  const [recentRaces, setRecentRaces] = useState([]);


  const { t } = useTranslation();
  const [currentTime, setCurrentTime] = useState(new Date());


    // Función para abrir el modal de selección de caballos
const openHorseModal = (raceid) => {
  setSelectedRace({ ...selectedRace, raceid });
  setModalSellarBtoOpen(true);
};


const openPremiosJModal = (raceid) => {
  setSelectedRace({ ...selectedRace, raceid });
  setIsOpenPremiosJ(true);
};
 // const getRecentRaces = (races) => {
 //    return races.slice(0, 6).reverse();
 //  };


  const getRecentRaces = (races) => {
  // Tomar las 6 últimas carreras
  const lastSixRaces = races.slice(0,6);
  
  // Ordenarlas por raceid de menor a mayor
  const sortedRecentRaces = lastSixRaces.sort((a, b) => a.raceid - b.raceid);
  
  return sortedRecentRaces;
};

  // Función para cerrar el modal de selección de caballos
  const closeHorseModal = () => {
    setModalSellarBtoOpen(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Actualiza el tiempo cada minuto

    return () => clearInterval(interval);
  }, []);

  // Restar la hora actual al tiempo de salida de la carrera para obtener el tiempo restante
  // const calculateTimeRemaining = (startTime) => {
  //   const start = new Date(startTime);
  //   const difference = start.getTime() - currentTime.getTime();
  //   const minutesRemaining = Math.floor(difference / (1000 * 60)); // Obtener minutos restantes

  //   return minutesRemaining >= 0 ? `🕒 ${minutesRemaining} min` : '';
  // };

  const calculateTimeRemaining = (startTime) => {
  const start = new Date(startTime);
  const difference = start.getTime() - currentTime.getTime();

  if (difference <= 0) return ''; // Si ya pasó la hora de inicio, no mostrar nada

  const daysRemaining = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hoursRemaining = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutesRemaining = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

  let timeString = '🕒 ';

  if (daysRemaining > 0) timeString += `${daysRemaining}d `;
  if (hoursRemaining > 0) timeString += `${hoursRemaining}h `;
  timeString += `${minutesRemaining}m`;

  return timeString;
};


  const openModalInf = (raceid, name) => { 
    setIsOpenInf(true);
    setSelectedRace({ raceid, name });
  };

    const openModalPremiosJ = (raceid, name) => { 
    setIsOpenPremiosJ(true);
    setSelectedRace({ raceid, name });
  };

  const closeModalInf = () => setIsOpenInf(false);
    
  const closeModalPremiosJ = () => setIsOpenPremiosJ(false);

  const toggleFilter = () => {
    setFilterByDate(!filterByDate);
    setLoading(true); // Volvemos a cargar las carreras al cambiar el filtro
    // fetchRaces(); // Volvemos a cargar las carreras al cambiar el filtro
    applySorting(races)
  };


//   const toggleFilterRaceType = () => {
//     if(racesType==="Oficial"){
//     setRacesType("Implemento");  
//     } else { setRacesType("Oficial")}
//       };




// const toggleTypoRace = () => {
//   // Alterna el estado del tipo de carrera
//   setFilterRacetype(!filterRacetype);
//   setLoading(true); // Reinicia la carga mientras se actualizan los datos
//   fetchRaces(); // Vuelve a cargar las carreras con el nuevo filtro
// };



const toggleTypoRace = () => {
  // Alterna el estado del tipo de carrera
  const newRaceType = !filterRacetype;
  setFilterRacetype(newRaceType); // Actualiza el estado
  setLoading(true); // Reinicia la carga mientras se actualizan los datos
  // fetchRaces(newRaceType); // Pasa el nuevo filtro a la función de carga
};

const fetchRaces = async () => {
  try {
    // Cargar las carreras solo una vez
    const raceType = filterRacetype ?    'Implemento ' : 'Oficial' ; // Usa el nuevo valor
    let url = `${linkApp}/race?page=1&type=${raceType}`;

    const response = await fetch(url);
    const data = await response.json();

    // Guardar todas las carreras sin ordenar inicialmente
    const allRaces = data.body;
    // Ordenar carreras de acuerdo al filtro activo
    await applySorting(allRaces);

  } catch (error) {
    console.error('Error al obtener las carreras:', error);
    setLoading(false);
  }
};




 const toggleAlertaSoon = () => {
   // setFilterRacetype(!filterRacetype);
    // Mostrar alerta personalizada
    const alertDiv = document.createElement('div');
    alertDiv.className = 'custom-alert';
    alertDiv.textContent = t('racein.OficialEspera'); 
    document.body.appendChild(alertDiv);
    // Eliminar la alerta después de unos segundos
    setTimeout(() => {
        document.body.removeChild(alertDiv);
    }, 3000);
};



// ...


  const openModalFromURL = () => {
    const fragment = decodeURIComponent(window.location.hash);
    if (fragment) {
      const params = new URLSearchParams(fragment.substring(1));
      const raceId = params.get('raceId');
      const name = params.get('name');
      setSelectedRace({ raceid: raceId, name: name });
      setIsOpenInf(true);
    }
  };




// const fetchRaces = async () => {
//   try {
//     // Construye la URL con el parámetro type basado en el estado filterRacetype
//     const raceType = filterRacetype ? 'Oficial' : 'Implemento'; // Ajusta según el filtro
//     let url = `${linkApp}/race?page=1&type=${raceType}`; // Agrega el parámetro type a la URL

//     const response = await fetch(url);
//     const data = await response.json();

//     let sortedRaces;
//     if (filterByDate) {
//       sortedRaces = data.body.sort((a, b) => b.raceid - a.raceid);
//     } else {
//       sortedRaces = data.body.sort((a, b) => new Date(b.tiempodesalida) - new Date(a.tiempodesalida));
//     }
    
//     setRaces(sortedRaces);
//     setLoading(false);
//   } catch (error) {
//     console.error('Error al obtener las carreras:', error);
//     setLoading(false);
//   }
// };


// const fetchRaces = async () => {
//   try {
//     // Cargar las carreras solo una vez
//     const raceType = filterRacetype ? 'Implemento ' : 'Oficial';
//     let url = `${linkApp}/race?page=1&type=${raceType}`;

//     const response = await fetch(url);
//     const data = await response.json();

//     // Guardar todas las carreras sin ordenar inicialmente
//     const allRaces = data.body;
//     // Ordenar carreras de acuerdo al filtro activo
//     applySorting(allRaces);

//     setLoading(false);
//   } catch (error) {
//     console.error('Error al obtener las carreras:', error);
//     setLoading(false);
//   }
// };

// Función para aplicar el ordenamiento según el filtro
const applySorting = (allraces) => {
  let sortedRaces;

  if (filterByDate) {
    // Ordenar por fecha de salida
    sortedRaces = allraces.sort((a, b) => new Date(b.tiempodesalida) - new Date(a.tiempodesalida));
  } else {
    // Ordenar por raceid (ID de la carrera)
    sortedRaces = allraces.sort((a, b) => b.raceid - a.raceid);
  }

  // Actualizar el estado con las carreras ordenadas
  setRaces(sortedRaces);
  setRecentRaces(sortedRaces.slice(0, 6)); // Guardar las últimas 6 carreras
  setLoading(false)
};



  useEffect(() => {
    fetchRaces();
    openModalFromURL();
    window.addEventListener('hashchange', openModalFromURL); // Para manejar cambios en el hash de la URL
    return () => window.removeEventListener('hashchange', openModalFromURL);
  }, [filterRacetype]); // Volvemos a cargar las carreras al cambiar el filtro

  useEffect(() => {
    const fetchApuestas = async () => {
      let account = localStorage.getItem("user");
      try {
        const response = await fetch(`${linkApp}/bets?usuario=${account}`);
        const data = await response.json();

        if (data.error) {
          // console.log("DATAERROR", data.error);
        } else {
          // console.log("DATOS DE APUESTAS", data.body); // Agregamos este console.log
          setApuestasUser(data.body);
        }
      } catch (error) {
        console.log('Error al cargar las Apuestas.');
      }
    };

    fetchApuestas();
    // Programar la próxima llamada a fetchApuestas después de 30 segundos
    const fetchInterval = setInterval(fetchApuestas, 320000);

    // Devolvemos una función de limpieza para detener el intervalo cuando el componente se desmonta
    return () => clearInterval(fetchInterval);
  }, []);

  function getStatusColor(status) {
    switch (status) {
      case 'abierta':
        return 'gold'; // Color para el estado 'abierta'
      case 'en_proceso':
        return 'red'; // Color para el estado 'en_proceso'
      // Agrega más casos para otros estados si es necesario
      default:
        return 'black'; // Color predeterminado
    }
  }

return (
  <div className="carreras-container">
    <div className="race-menu-nav">
      <h1 className="pancarta-text">{t('racein.carrera')}</h1>
    </div>
    <div className="race-menu-nav">
      <button onClick={toggleFilter} className="filtro-race-button">
        {filterByDate ? t('racein.filterByRaceId') : t('racein.filterByDate')}
      </button>
      <button onClick={toggleTypoRace} className="filtro-race-button">
        {filterRacetype ? t('racein.Oficiales') : t('racein.Comunes')}
      </button>
      {!filterRacetype && (
        <><button onClick={openHorseModal} className="pick-six-button">
          {t('racein.BuyTicket')}
        </button>
        <button onClick={openPremiosJModal} className="jornada-info-button">
          {t('racein.JornadasInfoButton')}
        </button>
</>
      )}
    </div>

    {loading ? (
      <Loader />
    ) : (
      <div className="race-list">
        {Array.isArray(races) && races.length > 0 ? (
          races.map((race) => (
            <RaceItem
              key={race._id}
              race={race}
              apuestasUser={apuestasUser}
              openModalInf={openModalInf}
              calculateTimeRemaining={calculateTimeRemaining}
              getStatusColor={getStatusColor}
            />
          ))
        ) : (
          <div className="no-data">{t('resultados.nohaydato')}</div>
        )}
      </div>
    )}

    {isOpenInf && (
      <ModalinFoRace
        isOpen={isOpenInf}
        closeModalInf={closeModalInf}
        raceId={selectedRace}
      />
    )}

    {ModalSellarBtoOpen && (
      <ModalSellarBoleto
        isOpen={ModalSellarBtoOpen}
        closeModal={closeHorseModal}
        raceId={selectedRace.raceid}
        recentRaces={getRecentRaces(races)}
      />
    )}


{isOpenPremiosJ && (
      <ModalPremiosJ
        isOpenPremiosJ={isOpenPremiosJ}
        closeModalPremiosJ={closeModalPremiosJ}
        // raceId={selectedRace}
        recentRaces={getRecentRaces(races)}
      />
    )}





  </div>






);

}

export default RacesComponent;


import { LanguageProvider } from "./components/context/LanguageContext";
import { ThemeProvider } from "./components/context/ThemeContext";
import { SistemasActivosProvider  } from "./components/context/SistemasActivosContext";
import Footer from "./components/fotter/Footer";
import Header from "./components/header/Header";
import { Outlet } from "react-router-dom";





const MyPageContext = () => {


  return (
    <div className="my-page">
      
        <ThemeProvider>
          <LanguageProvider>
          <SistemasActivosProvider>
            <Header/> 
           <Outlet/>
           <Footer/>
           </SistemasActivosProvider>
          </LanguageProvider>
        </ThemeProvider>
    
    </div>
  );
};

export default MyPageContext;
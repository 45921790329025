import React from 'react';
 import './../css/HCardTest.css';


const HorseCard = ({ horse }) => {

  // const velocidadTotal = Number(horse.velocidad) + Number(horse.velocidad_add);
  // const resistenciaTotal = Number(horse.resistencia) + Number(horse.resistencia_add);

const velocidadTotal = (Number(horse.velocidad || 0) + Number(horse.velocidad_add || 0)).toFixed(0);
const resistenciaTotal = (Number(horse.resistencia || 0) + Number(horse.resistencia_add || 0)).toFixed(0);

 

  return (
    <div className="horse-owner">
  <h2>👑{horse.account}</h2>
<div className="horse-card">
  <h2 className="rarity">{horse.tipo}</h2>
  <div className="image-container">
    <img src={`./image/equinos/${horse.equineId}.png`} alt={horse.nameBhr} className="horse-image" />
  </div>
  <p className={`status ${horse.estado.toLowerCase()}`}>Nft ID: {horse.equineId}</p>

  <div className="stats">
    <div className="stat-item">
      < h5><span>Speed</span> {velocidadTotal}</ h5>
      <div className="progress-bar" style={{ width: `${velocidadTotal / 2}%` }}> </div>
    </div>
    <div className="stat-item">
      < h5><span>Endurance</span> {resistenciaTotal}</ h5>
      <div className="progress-bar" style={{ width: `${resistenciaTotal / 2}%` }}></div>
    </div>
    <div className="stat-item">
      <h5><span>Agility</span> {horse.agilidad}</h5>
      <div className="progress-bar" style={{ width: `${horse.agilidad / 2}%` }}></div>
    </div>
  </div>
  <h4>{horse.nameBhr || "noname"}</h4>
  {/*<div className="awards">{horse.status_oficial}</div>*/}
    <button className="btn-train">Auction</button>
  <button className="btn-history">More</button>
</div>
  
    
      <div className="awards">
      {horse.status_oficial}

      {/*  {horse.carreras_third.map((race, index) => (
          <div key={index} className="award">
            <span>Carrera {race.race}</span>
            <span>{race.Lugar === 3 ? '🥉 Tercer Lugar' : `Lugar: ${race.Lugar}`}</span>
          </div>
        ))}*/}
      </div>
   
    </div>
  );
};

export default HorseCard;





import axios from 'axios';

import { linkApp } from './../../configuracion/variablesPublicas.jsx';


export const obtenerDatosWallet = async (usuario) => {
 

  try {
 
    // Simulación de retraso
    await new Promise(resolve => setTimeout(resolve, 500));

    const response = await axios.get(`${linkApp}/wallet/?usuario=${usuario}`);
    const data = response.data.body[0];

    // Procesar la información recibida
    const balanceDrops = data.balance_drops || 0;
    const retirosDrops = data.retiros_drops || 0;
    const saldo = (data.balance - data.retiros).toFixed(2) || 0;
    const tickets = (data.tickets * 100).toFixed(2) || 0;

    const ganancia_a = (data.ganancia_apuestas || 0).toFixed(2);
    const ganancia_b = (
      (data.ganancias_apuestas_segundoLugar || 0) +
      (data.ganancias_apuestas_tercerLugar || 0) +
      (data.ganancias_apuestas_quintoLugar || 0) +
      (data.ganancias_apuestas_sextoLugar || 0)
    ).toFixed(2);

    const ganancia_BHR = (data.ganancias_apuestas_cuartoLugar || 0).toFixed(3);
    
    // Retornar la información procesada
    return {
      status: 'success',
      data: {
        saldo,
        tickets,
        ganancia_a,
        ganancia_b,
        ganancia_BHR,
        status_retiro: data.status_retiro
      }
    };
  } catch (error) {
    console.error(error);
    throw new Error('Error al obtener los datos de la wallet');
  }
};

import React, { useState, useEffect } from 'react';
import './../../../css/modal/ModalMail.css'; // Asegúrate de tener el CSS en el mismo directorio o ajusta la ruta
import hivelogo from'../../../../../../../../../assets/logoblockhorseracing/logoHive.png';
import logosBHR from'../../../../../../../../../assets/logoblockhorseracing/logoBlock.png';
import logosBHRT from'../../../../../../../../../assets/logoblockhorseracing/coinBHRT2.png';

import { obtenerDatosWallet } from './../../../../../../../../generals/servicios/wallet/getSaldosW.jsx';
import { get_Torneo} from './../../../../../../../../generals/servicios/torneo/getTorneo.jsx';
import { registrarCaballo } from './../../../../../../../../generals/servicios/registrarEnTorneo.jsx';
import PoolsTable from './components/PoolsTable.jsx'



const ModalM_Inscritos = ({ isOpenMail, toggleOpenMail, datos, isVisibleMail }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(localStorage.getItem("user"));
  const [showModal, setShowModal] = useState(false);
  const [premioFase1, setPremioFase1] = useState(null);
  const [premioFase2, setPremioFase2] = useState(null);
  const [premioFase3, setPremioFase3] = useState(null);
  const [premioFase4, setPremioFase4] = useState(null);
  const [registrados, setRegistrados] = useState(0);
  const [pool1, setPool1] = useState(null);
  const [pool2, setPool2] = useState(null);
  const [pool3, setPool3] = useState(null);
  const [idTorneo, setIdTorneo] = useState(null);
  const [status, setStatus] = useState('');
  const [statusInscripciones, setStatusInscripciones] = useState('');
  const [symbol1Registro, setSymbol1Registro] = useState('');
  const [symbol2Registro, setSymbol2Registro] = useState('');
  const [symbol3Registro, setSymbol3Registro] = useState('');
  const [tipo, setTipo] = useState('');


console.log("DATOS",datos.id)
console.log("isVisibleMail",isVisibleMail)



let mail=[]
 mail.title='Felicitaciones, Bienvenido al torneo '
 
 mail.body= '<ol style="padding-left: 1px; font-size: 14px; color: #1E3A8A; line-height: 1.25; margin-bottom: 16px; max-width: 280px; word-wrap: break-word;"><p>Cada torneo incluye</p> <p>tres emocionantes fases:</p><li style="margin-bottom: 1px;">Clasificatoria.</li><li style="margin-bottom: 1px;">Semifinales.</li><li style="margin-bottom: 1px;">Gran Final.</li></ol>'
 
 mail.footer='Este registro asegura la participación de tu equino durante toda el torneo. ¡Buena suerte!'

  const getIconBySymbol = (symbol) => {
    switch (symbol) {
      case 'BHRT':
        return logosBHRT;
      case 'SWAP.HIVE':
        return hivelogo;
        case 'BHR':
        return logosBHR;
      default:
        return hivelogo; // O un ícono por defecto si lo necesitas
    }
  };














useEffect(() => {
    if (isVisibleMail && user) {  // Solo ejecutar si el componente está visible y `user` está definido
      const fetchData = async () => {
        try {
          const torneo_Abierto = await get_Torneo();
          const { Premio_Fase_1, Premio_Fase_2, Premio_Fase_3, Premio_Fase_4, Registrados,pool_total_1,pool_total_2,pool_total_3,id_Torneo,status,status_inscriciones,symbol_1_registro,symbol_2_registro,symbol_3_registro,tipo } = torneo_Abierto.data.body[0];
 
    
          // Desestructurar y mostrar en consola
          console.log("BUQUEDATORNEO", torneo_Abierto.data.body);
          setPremioFase1(Premio_Fase_1);
          setPremioFase2(Premio_Fase_2);
          setPremioFase3(Premio_Fase_3);
          setPremioFase4(Premio_Fase_4);
          setRegistrados(Registrados.length);
          setIdTorneo(id_Torneo);
          setStatus(status);
          setStatusInscripciones(status_inscriciones);
          setSymbol1Registro(symbol_1_registro);
          setSymbol2Registro(symbol_2_registro);
          setSymbol3Registro(symbol_3_registro);
          setTipo(tipo);
          setPool1(pool_total_1);
          setPool2(pool_total_2);
          setPool3(pool_total_3);
 // Mostrar el modal con los resultados
        } catch (error) {
          setError(error.message);
          console.error(error);
        }
      };

      fetchData();
    }
  }, [isVisibleMail, datos.id]); // Ejecutar solo cuando el componente sea visible o `user` cambie



  // Función para cerrar el modal
  const closeModal = () => {
    toggleOpenMail(); // Utiliza la función del padre para alternar el estado
  };

  // Función para manejar el registro
  const handleRegistro = async (token,costo) => {
    setLoading(true);
    setError(null);
    
    const datosCaballo = {
      ...datos,
      token,
      costo, // Puedes pasar el tipo de token (BHRT o HIVE) dependiendo de qué botón se presione
    };

    const result = await registrarCaballo(datosCaballo);
    
    setLoading(false);

    if (result.success) {
      alert('Registro exitoso');
    } else {
      setError(result.error);
    }
  };











// Función que devuelve el saldo basado en el símbolo
// const getSaldoBySymbol = (symbol) => {
//   switch (symbol) {
//     case 'BHRT':
//       return saldoBhrt;
//     case 'HIVE':
//       return saldoBhr;
//     default:
//       return saldo; // Saldo general
//   }
// };

// Función que devuelve si el botón debe estar deshabilitado




  return (
    <>
      {/* Modal */}
      {isOpenMail && (
        <div className="modalMail-overlay" onClick={closeModal}>
          <div className="modalMail-content" onClick={(e) => e.stopPropagation()}>

            <div className="modalMail-header">
              <button className="close-button" onClick={closeModal}>✖️</button>
            </div>
            <div className="modalMail-body">
              <div className="letter-container">
               <p className="letter-text">{mail.title}</p>
                   <div dangerouslySetInnerHTML={{ __html: mail.body }} />
               <p className="letter-text">{mail.footer}</p>
                   
                <div className="sello"></div>

                {loading ? (
                  <p>Registrando...</p>
                ) : (
                  <>
    {/* Botón para el primer registro */}
{/* Botón para el primer registro */}
<PoolsTable swapHive={pool3}  bhrt={pool1}  bhr={pool2}> </PoolsTable>


                  </>
                )}

                {error && <p className="error-message">{error}</p>}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalM_Inscritos;

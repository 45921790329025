import  React, { useState, useEffect }from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { parse, isValid, isBefore } from "date-fns";
import { useTranslation } from 'react-i18next';
import Icon from './../../../generals/svgiconos/svgiconosenreact.jsx';
import { linkApp } from './../../../generals/configuracion/variablesPublicas.jsx';

import'./tablaDatos.css'

let link="http://localhost:4000";
const traducciones = {
  'D0': 'Debutante',
  'G1': 'Ganadores de 1',
  'G2': 'Ganadores de 2',
  'G3': 'Ganadores de 3',
  'G4': 'Ganadores de 4',
  'G5': 'Ganadores de 5',
  'L0':'Libre',
  'P0': 'Perdedores',
  'C1': 'Ganadores de 1*',
  'C2': 'Ganadores de 2*',
  'C3': 'Ganadores de 3*',
  'C4': 'Ganadores de 4*',
  'C5': 'Ganadores de 5* o mas',
  'P3': 'Perdedores de 3*',
  'P4': 'Perdedores de 4*',
  'P5': 'Perdedores de 5 o mas',
};

function traducirTipo(tipo) {
  return traducciones[tipo] || '';
}



function minutosFaltantes(dateString) {
  // Primero, intentamos convertir la cadena de fecha dada a un objeto Date
  // utilizando varios formatos de fecha posibles.
  let date = null;
  const dateFormats = ["yyyy-MM-dd'T'HH:mm:ss.SSSxxx","yyyy-MM-dd HH:mm:ss", "yyyy-MM-dd HH:mm", "yyyy-MM-dd"];
  for (const format of dateFormats) {
    date = parse(dateString, format, new Date());
    if (isValid(date)) {
      break;
    }
  }

  // Si la fecha no pudo ser convertida a un objeto Date válido,
  // devolvemos null para indicar que la fecha dada no es válida.
  if (!date) {
    return null;
  }

  // Si la fecha dada es en el pasado, devolvemos 0 para indicar que no hay
  // minutos que faltan para llegar a la fecha.
  if (isBefore(date, new Date())) {
    return 0;
  }

  
  const secondsDiff = date.getTime() - new Date().getTime();
  const minutesDiff = Math.ceil(secondsDiff / 60000);
  return minutesDiff;
}













// Crea una función que se ejecute cada segundo y actualice el valor de los minutos restantes
function actualizarMinutos(tiempodesalida) {
  // Obtener el valor de la fecha de salida
  console.log("tiempodesalida",tiempodesalida)
  let fecha = new Date(tiempodesalida);

  // Calcular los minutos restantes
  let minutosRestantes = minutosFaltantes(fecha);

  // Actualizar el valor de los minutos restantes en la interfaz de usuario
  // ...
  return minutosRestantes
}

// Iniciar la actualización de los minutos restantes cada segundo
setInterval(minutosFaltantes, 1000);


export default function TablaCrr(props) {
  const [mostrarDiv, setMostrarDiv] = useState(false);
   const [equinosC, setEquinosC] = useState([]);
   const[showEquinosInscritos,setShowEquinosInscritos]= useState(false);
  const { data, propsi ,fecha} = props;
  const { t } = useTranslation();
  // console.log("identida d TCrr",propsi.identidad)
    // console.log("PROS d TCrr",propsi)
     // console.log("DATA d TCrr",data)
  //console.log(data)
//console.log("aqui", data[0].status)



useEffect(() => {
  let registrados = [];
  try {
    registrados = data[0].registrados;
    console.log("registrAdis", data);
    console.log("registrAdos", registrados);

    if (registrados.length > 1) {
      const numerosConsulta = registrados.join(',');

      axios
        .get(`${linkApp}/equino?equineId=${numerosConsulta}`)
        .then(response => {
          const equinos = response.data.body;
          setEquinosC(prevEquinosC => [...prevEquinosC, ...equinos]);
          setShowEquinosInscritos(!showEquinosInscritos)
        })
        .catch(error => {
          console.error(error);
          // Manejar errores, puedes establecer un estado de error para mostrar un mensaje al usuario.
        });
    }
  } catch (error) {
    // setError('Error al cargar equinos.');
  }
}, [data, propsi.identidad]);




console.log(equinosC)


  return (<>
     { data[1] &&
      <div className="titulo_Carrera"><p>ID:{data[1].id}-{data[1].name}</p></div>
    }
         
    <div className="table">
      <div className="row header">
     {/*   <div className="cell">{t('tablaCarrera.info')}</div>
        <div className="cell">{t('tablaCarrera.premios')}</div>
        <div className="cell">{t('tablaCarrera.salida')}</div>*/}
        
       
      </div>
     {data.map(item => (
        <div className="row">
        <div className="cell">
          <p><h5>{item.name}</h5></p>
  {item.grado && <p>{item.grado}</p>}
              
        <div>
          <p>Grado</p>
            <div>
  
    <p>Invited:
      <h5>{item.tipo
        .split(',')
        .map((t) => traducirTipo(t))
        .join(', ')}
    </h5></p>
  </div>
        </div>

      




 
    <p>{t('tablaCarrera.registrados')}: {item.registrados.length }</p>

  {item.registrados &&

    item.registrados.map((registrado, index) => (
      <span>
        {registrado}
        {index < item.registrados.length - 1 && ", "}
        {(index + 1) % 7 === 0 && <br />}
      </span>
    ))}
   

</div>
          <div className="cell"><p>{item.premiostotal_1} {item.symbol_1}</p>
          <p>{item.premiostotal_2} {item.symbol_2}</p><p>{item.premiostotal_3} {item.symbol_3}</p></div>
          
             <div className="cell"><p>{minutosFaltantes(item.tiempodesalida)}min</p>
             
            </div>
         

        </div>
      ))}
    </div>

    { showEquinosInscritos && equinosC ? (
          <table>
            <thead>
              <tr>
              
                <th></th>
                 <th>{t('resultados.estadisticas')}</th>
             </tr>
            </thead>
            <tbody>
              { equinosC
                .map((eq, index) => (
                  <tr key={eq._id}>
               
  <td>
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
👑{eq.account} 
  </div>
   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  {eq.sexo === 'M' ? '♂️' : '♀️'} <span style={{ fontSize: '0.7rem' }}>&nbsp;&nbsp;{eq.tipo}&nbsp;&nbsp;</span>
 {eq.equineId}

  </div>
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img
        src={`/image/equinos/${eq.equineId}.png`}
        alt="Img"
        className={`icono-races`}
        
      />
    </div>
   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <h5>{eq.name}</h5> </div>

 






</td>




<td>

 <div className="trapecio-race-active" style={{ top: '0px' }}>
  

<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'green' }}>
    <Icon name="agilidad" width="2rem" height="2rem" />{eq.agilidad}
  </div>
     
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'gold',color: "#222" }}>
       <Icon name="resistencia" width="2rem" height="2rem" />{eq.resistencia}+<span>{isNaN(parseInt(eq.resistencia_add)) ? 0 : parseInt(eq.resistencia_add)}</span>
  </div>
    
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'red' }}>
  <Icon name="velocidad" width="2rem" height="2rem" />&nbsp; {eq.velocidad}+<span>{isNaN(parseInt(eq.velocidad_add)) ? 0 : parseInt(eq.velocidad_add)}</span>
  </div>
     <div >
     ❤️&nbsp;&nbsp;{parseInt(eq.animo) + parseInt(eq.alimentos_puntos)}
      🏇&nbsp;&nbsp;{isNaN(parseInt(eq.implemento)) ? 0 : parseInt(eq.implemento)}
  </div>
      <div >

  </div>
  </div>
</td>

                  </tr>

                ))}
            </tbody>
          </table>):null }




    </>
  );
}



import React from 'react';
import PropTypes from 'prop-types';
import './../css/Ganadores.css';
import { useTranslation } from 'react-i18next';


const Ganadores = ({ eq_ganadores }) => {
  const { t } = useTranslation();
  
  if (!eq_ganadores || eq_ganadores.length === 0) {
    return <p>{t("racein.jornada_5y6.info.noHayGanadoresAun")}.</p>;
  }

  return (
    <div className="ganadores-section">
      <h3>Equinos Ganadores</h3>
      <div className="ganadores-grid">
        {eq_ganadores.map((ganador, index) => (
          <div key={index} className="ganador-column">
            <p><strong>[{t("general.Race")}: {ganador.race}]</strong></p>
                
            <p>{t("racein.jornada_5y6.info.PP")}: {ganador.ppartida}</p>
            <p>{t("racein.jornada_5y6.info.EqId")}: {ganador.winner}</p>
            <p>{t("racein.jornada_5y6.info.Points")}: {ganador.ptos}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

Ganadores.propTypes = {
  eq_ganadores: PropTypes.arrayOf(
    PropTypes.shape({
      race: PropTypes.number.isRequired,
      winner: PropTypes.number.isRequired,
      ppartida: PropTypes.number.isRequired,
      ptos: PropTypes.number.isRequired,
      confirmado: PropTypes.bool.isRequired
    })
  ).isRequired
};

export default Ganadores;

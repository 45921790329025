import React, { useState } from 'react';
import Loader from './../../../../../../generals/loders/Loader.jsx';
import EquinoRow from './equinostabla/EquinoRow';
import './css/equinostable.css';
import hivelogo from'../../../../../../../assets/logoblockhorseracing/logoHive.png';


const EquinosTable = ({ equinos, fetchApuestas }) => {
  const { equinosT, raceId,raceName ,apuestas } = equinos;
  const [isLoadingPatrocinio, setIsLoadingPatrocinio] = useState(true);

console.log("apppppppuestaaaaaaaaaaaaas",apuestas)
  setTimeout(() => setIsLoadingPatrocinio(false), 2);

  return (
    <div>
      {isLoadingPatrocinio ? (
        <>
          <Loader />
          <p>Loading...</p>
        </>
      ) : (
        <table className="equinos">
          <thead>
            <tr>
            <th>Race:{raceId}</th>
                 <th colSpan="3"> {raceName}</th>
                 <th><h1>💰{(apuestas.Total_Pote).toFixed(2)} <img src={hivelogo} alt="Token" className="coin" /></h1></th>
            </tr>
          </thead>
          <tbody>
            {equinosT.reduce((uniqueEquinos, eq) => {
              if (!uniqueEquinos.some(uniqueEq => uniqueEq.equineId === eq.equineId)) {
                uniqueEquinos.push(eq);
              }
              return uniqueEquinos;
            }, []).map((eq, index) => (
              <EquinoRow
                key={eq.equineId}
                equino={eq}
                index={index}
                raceId={raceId}
                apuestas={apuestas}
                fetchApuestas={fetchApuestas}
              />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default EquinosTable;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logos from'../../../../../../../assets/logoblockhorseracing/coinBHRT2.png';
import hivelogo from'../../../../../../../assets/logoblockhorseracing/logoHive.png';
import logosBHR from'../../../../../../../assets/logoblockhorseracing/logoBlock.png';

import { useTranslation } from 'react-i18next';
import { linkApp } from './../../../../../../generals/configuracion/variablesPublicas.jsx';
import ModalWalletBHRT from './modals/modalWalletProfitsBHRT';
import ModalWalletBHR from './modals/modalWalletProfitsBHR';


const FetchWallet = () => {
  const [walletData, setWalletData] = useState(null);
  const [saldo, setSaldo] = useState(null);
   const [tickets, setTickets] = useState(null);
   const [ganancia, setGanancia] = useState(null);
   const [gananciaConsuelo, setGananciaConsuelo] = useState(null);
   const [gananciaConsueloBHR, setGananciaConsueloBHR] = useState(null);
   const [seleccion, setSeleccion] = useState('0.5'); // Definimos el estado para el valor seleccionado
   const [seleccionDepositarVisible, setseleccionDepositarVisible] = useState(false);
   const [seleccionRetirarVisible, setseleccionRetirarVisible] = useState(false);
   const [statusRetiro, setStatusRetiro] = useState(false);
   const [isOpenInf, setIsOpenInf] = useState(false);
   const [isOpenModalBHRClaim, setIsOpenModalBHRClaim] = useState(false);

   
   const closeModalInf = () => setIsOpenInf(false);
    const closeModalBHRClaim  = () => setIsOpenModalBHRClaim(false);
   const { t } = useTranslation();


console.log("seleccion",seleccion)




  const openModalInf = (id) => { setIsOpenInf(true);
    
    console.log("isOpenInf",isOpenInf)
    // setSelectedId(id);

   // console.log("ABIERTO CABALLO EN MODAL INFO",id)
  }




 const openModalBHRClaim = (id) => { 
  setIsOpenModalBHRClaim(true);
    
    // console.log("isOpenInf",isOpenInf)
  }

  useEffect(() => {
    const user = localStorage.getItem("user");





    // Simulación de retraso de medio segundo (500 milisegundos)
    const delay = 500;

    // Realizar la solicitud StatusRetirode Fetch aquí después del retraso
    setTimeout(() => {
      fetch(`${linkApp}/wallet/?usuario=${user}`)
        .then((response) => response.json())
        .then((data) => {
         // //console.log("DATAWALLET",data)
          // const balanceDrops = data.body[0].balance_drops;
          // const retirosDrops = data.body[0].retiros_drops;
          // const result = (balanceDrops - retirosDrops).toFixed(2);
          // setWalletData(result);
          // const balance = data.body[0].balance;
          // const retiros = data.body[0].retiros;
          // const saldo = (balance - retiros).toFixed(2);
          // const ganancia_a = (data.body[0].ganancia_apuestas).toFixed(2);
          // const status_retiro=data.body[0].status_retiro
          // const ganancia_b=(data.body[0].ganancias_apuestas_segundoLugar + data.body[0].ganancias_apuestas_tercerLugar).toFixed(2)
          // const ganancia_BHR=(data.body[0].ganancias_apuestas_cuartoLugar).toFixed(3)
        
        const balanceDrops = data.body[0].balance_drops || 0;
const retirosDrops = data.body[0].retiros_drops || 0;
const result = (balanceDrops - retirosDrops).toFixed(2);
setWalletData(result);

const balance = data.body[0].balance || 0;
const retiros = data.body[0].retiros || 0;
const saldo = (balance - retiros).toFixed(2);
const tickets = (data.body[0].tickets * 100).toFixed(2) || 0;

const ganancia_a = (data.body[0].ganancia_apuestas || 0).toFixed(2);
const status_retiro = data.body[0].status_retiro;

const ganancia_b = (
  (data.body[0].ganancias_apuestas_segundoLugar || 0) +
  (data.body[0].ganancias_apuestas_tercerLugar || 0) +
  (data.body[0].ganancias_apuestas_quintoLugar || 0) +
  (data.body[0].ganancias_apuestas_sextoLugar || 0) 
).toFixed(2);

const ganancia_BHR = (data.body[0].ganancias_apuestas_cuartoLugar || 0).toFixed(3);

          setStatusRetiro(status_retiro);
          setSaldo(saldo);
          setGanancia(ganancia_a)
          setGananciaConsuelo(ganancia_b)
          setGananciaConsueloBHR(ganancia_BHR)
          setTickets(tickets)
          
        })
        .catch((error) => console.log(error));
    }, delay);
  }, [saldo]);




const handleDepositarVista = () => {
    if (!seleccionDepositarVisible) {
      // Si el select no es visible, lo hacemos visible
      setseleccionDepositarVisible(true);
      setseleccionRetirarVisible(false);
    } else {
      // Si el select ya es visible, ejecutamos la función handleDepositarSaldo
      setseleccionDepositarVisible(false);
      // handleDepositarSaldo(seleccion);
    }
  };


const handleRetirarVista = () => {
    if (!seleccionRetirarVisible) {
      // Si el select no es visible, lo hacemos visible
      setseleccionDepositarVisible(false);
      setseleccionRetirarVisible(true);
    } else {
      // Si el select ya es visible, ejecutamos la función handleDepositarSaldo
     setseleccionRetirarVisible(false);
      // handleDepositarSaldo(seleccion);
    }
  };


const handleDepositarSaldo = () => {
  manejarDeposito(seleccion);
};


const manejarDeposito = async (seleccion) => {

  console.log("SALDO SEL",seleccion)
let memo=`Add Balance BHR EQ`
let toUsername="equino"
let username=localStorage.getItem("user");
let amount="" + Number(seleccion).toFixed(3)
//let amount="1.000" 


if(saldo<=15){

const total_prox = Number(seleccion) + Number(saldo);
console.log("TOTOTAL",total_prox)
const limiteMaximo = 15;

if (total_prox > limiteMaximo) {
  amount = limiteMaximo - saldo;
  amount = "" + amount.toFixed(3)
}
if (window.hive_keychain) {
  const keychain = window.hive_keychain;
  keychain.requestSendToken(username, toUsername, amount, memo, 'SWAP.HIVE', (response) => {
    console.log("RES KeyC",response);
    alert(response.message)
    axios.post(`${linkApp}/deposit_wallet`, response) 
      .then(res => {
        //console.log("axio response",response)
       
     })
      .catch(error => {
        //console.log("error DE post axio",error)
        // manejar cualquier error que ocurra durante la llamada a la API
      });
  });
} else {
  alert('You do not have hive keychain installed');
}
}else{
  alert('alcanzaste el saldo maximo permitido en wallets', 5)
}


};






const handleRetiro = async (data, amounte) => {
  const username = localStorage.getItem("user");
  const consulta = { usuario: username, balance_drops: Number(data), status_claim: "create" };


  // Enviar la transacción firmada al servidor utilizando Axios
  axios.post(`${linkApp}/claim_drop`, consulta)
    .then(res => {
      // Mostrar la respuesta en una alerta emergente
      alert(`Respuesta del servidor: ${JSON.stringify(res.data.body)}`);
    })
    .catch(error => {
      //console.log("error DE post axios", error);
      // Manejar cualquier error que ocurra durante la llamada a la API
    });
};

const handleRetiroSaldo = async (data, amounte) => {
  const username = localStorage.getItem("user");
  const consulta = { usuario: username, balance: Number(data)};


if(Number(data) > 0.5){  

if (statusRetiro !== "pending" && statusRetiro !== "whitdrawing"){
  // Enviar la transacción firmada al servidor utilizando Axios
  axios.post(`${linkApp}/claim_balance`, consulta)
    .then(res => {
      // Mostrar la respuesta en una alerta emergente
      alert(`Respuesta del servidor: ${JSON.stringify(res.data.body)}`);
    })
    .catch(error => {
      //console.log("error DE post axios", error);
      // Manejar cualquier error que ocurra durante la llamada a la API
    });

}else{ alert("You must wait for the withdrawal to be fully processed") }
}else{alert("The minimum withdrawal allowed is 0.500 Swap.Hive")};


}

const handleClaimGanancia = async (data) => {
  const username = localStorage.getItem("user");
  const requerimiento = { usuario: username, ganancia_apuestas: Number(data)};

  // Enviar la transacción firmada al servidor utilizando Axios
  axios.post(`${linkApp}/claim_ganancia`, requerimiento)
    .then(res => {
      // Mostrar la respuesta en una alerta emergente
      alert(`Respuesta del servidor: ${JSON.stringify(res.data.body)}`);
    })
    .catch(error => {
      //console.log("error DE post axios", error);
      // Manejar cualquier error que ocurra durante la llamada a la API
    });
};


const handleClaimGananciaConsuelo = async (data) => {
  const username = localStorage.getItem("user");
  const requerimiento = { usuario: username, ganancia_apuestas_consuelo: Number(data)};

if(Number(data)>=10){
  // Enviar la transacción firmada al servidor utilizando Axios
  axios.post(`${linkApp}/claim_ganancia`, requerimiento)
    .then(res => {
      // Mostrar la respuesta en una alerta emergente
      alert(`Respuesta del servidor: ${JSON.stringify(res.data.body)}`);
    })
    .catch(error => {
      //console.log("error DE post axios", error);
      // Manejar cualquier error que ocurra durante la llamada a la API
    });
  }else{
    alert(`${t('wallet.retiroMinimoBHRT')}`);
  }
};


  return (
    <div>
      {walletData !== null ? (
        <div className="wallet">
         <td>
         <span>{t('wallet.profit')}<br/><h5> {gananciaConsueloBHR}<img src={logosBHR} alt="Token"  className="coin" /></h5></span>
         
   
        <button onClick={() => openModalBHRClaim(gananciaConsueloBHR)}><span>{t('wallet.claim')}</span></button>
         {isOpenModalBHRClaim && (  
          <ModalWalletBHR isOpen={isOpenModalBHRClaim} closeModal={closeModalBHRClaim}   propsi={gananciaConsueloBHR}  onClose={() => setIsOpenInf(false)}   >

         <button className="modal-close" onClick={closeModalInf}>
        X   ACACACACAC
        </button>


    </ModalWalletBHR> )}
</td>  
         <td>
         <span>{t('wallet.profit')}<br/><h5> {gananciaConsuelo}<img src={logos} alt="Token"  className="coin" /></h5></span>
         
        {/*<button onClick={() => handleClaimGananciaConsuelo(gananciaConsuelo)}>{t('wallet.claim')}</button>*/}
        <button onClick={() => openModalInf(gananciaConsuelo)}><span>{t('wallet.claim')}</span></button>
        {isOpenInf && (  
          <ModalWalletBHRT isOpen={isOpenInf} closeModal={closeModalInf}   propsi={gananciaConsuelo}  onClose={() => setIsOpenInf(false)}   >

         <button className="modal-close" onClick={closeModalInf}>
        X
        </button>


    </ModalWalletBHRT> )}
</td>  



        <td >  
        <span>{t('wallet.minado')}<br/><h5>{walletData} <img src={logos} alt="Token"  className="coin" /></h5></span><button onClick={() => handleRetiro(walletData)}><h1>📤</h1></button></td>  
        
        <td className="walletBalanceTabla">


          {tickets > 0 && (
        <span>
          tickets: <h5>🎟️{tickets}</h5>
        </span>
      )}



     <span>
  Balance {statusRetiro === 'pending' ? '⏳' : statusRetiro === 'whitdrawing' ? '🚦' : ''}
  <br />
  <h5>
    {saldo} <img src={hivelogo} alt="Token" className="coin" />
  </h5>
</span>
<span>
      {seleccionDepositarVisible && (
    <td>   
    <tr>
        <select onChange={(e) => setSeleccion(e.target.value)}>
          <option value="0.5">0.5 Swap.Hive</option>
          <option value="1">1 Swap.Hive</option>
          <option value="2">2 Swap.Hive <img src={hivelogo} alt="Token" className="coin" /></option>
          <option value="3">3 Swap.Hive</option>
          <option value="5">5 Swap.Hive</option>
         </select> </tr>
 <button onClick={handleDepositarSaldo}><span> {t('wallet.depositar')}</span></button>
 </td>
      )}

 {seleccionRetirarVisible && (
         <span>
      <button onClick={() => handleRetiroSaldo(saldo)}> 
      <span> {t('wallet.retirar')}</span></button>   
        </span>
      )}

      <button onClick={handleDepositarVista}><h1>📥</h1></button>
      <button onClick={handleRetirarVista}> <h1>📤</h1></button>
  </span></td>
        


<td>  <span>{t('wallet.profit')}<br/><h5>{ganancia} <img src={hivelogo} alt="Token"  className="coin" /></h5></span><button onClick={() => handleClaimGanancia(ganancia)}><span>{t('wallet.claim')}</span></button></td>  
           
       
                
        </div>
      ) : (
        <p><span>&nbsp; {t('wallet.cargando')}&nbsp;</span></p>

      )}


{/*    <div>
      <ModalWalletBHRT isOpen={isOpenInf} closeModal={closeModalInf}   propsi={gananciaConsuelo} >

         <button className="modal-close" onClick={closeModalInf}>
        X
        </button>


    </ModalWalletBHRT>

   

    </div>*/}
    </div>
  );
};

export default FetchWallet;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TablaCarreras from './../tables/TablasCarrera.jsx';//NUEVA FORMA
import "./RaceBalloModal.css"
import TablaCrr from './../tables/TablaCrr.jsx';
import { useTranslation } from 'react-i18next';
import Icon from './../../../generals/svgiconos/svgiconosenreact.jsx';
import { linkApp } from './../../../generals/configuracion/variablesPublicas.jsx';
import ChangeHorseName from'./components/raceballo/ChangeHorseName.jsx';
 import EquineImage from'./../../../generals/herramientas/imagenesCaballos.jsx';
import ModalCloseButton from './components/general/ModalCloseButton'; // Asegúrate de que la ruta sea correcta
import HorseInfo from './components/raceballo/horseinfo/HorseInfo'; // Asegúrate de que la ruta sea correcta
import ModalMail from './components/raceballo/modalmail/ModalMail.jsx'; // Asegúrate de que la ruta sea correcta
import ModuloM_Inscrito from './components/raceballo/modalmail/componentsMMail/MailParaInscritos/MailParaInscritos.jsx'; // Asegúrate de que la ruta sea correcta

 






function carreras_list(T,W,C){
 let carreras=""
 if(T>0){
    if(W>0){
     if(W===1){carreras="G1"}
     if(W===2){carreras="G2"}
     if(W===3){carreras="G3"}
     if(W===4){carreras="G4"}
     if(W===5){carreras="G5"}
     if(W>5){
      if(C===0){carreras="PC"}
      if(C===1){carreras="C1"}
      if(C===2){carreras="C2"}
      if(C===3){carreras="C3"}
      if(C===4){carreras="C4"}
      if(C===5){carreras="C5"}
      if(C> 5){carreras="C6"} } 
      }else if(W===0){
        carreras="P0"
    }
  }else{
    carreras="D0"
  }

return carreras 

}






const ModalRace = ({ children, isOpen,openModal, closeModal, propsi }) => {
   const [data, setData] = useState(null);
   const [isLoadingData, setIsLoadingData] = useState(true);
   const [isCancelledData, setIsCancelledData] = useState(false);
   const [equino, setEquino] = useState(null);
   const [isLoadingEquino, setIsLoadingEquino] = useState(true);
   const [isCancelledEq, setIsCancelledEq] = useState(false);
   const [loading_race_status_create, setLoading_race_status_create] = useState(false);
   const [isLoading_registro_oficial, setIsLoading_registro_oficial] = useState(false);
   const [MostrarBoton_Registro, setMostrarBoton_Registro] = useState(false);

   const [isVisibleMail,setIsVisibleMail]= useState(false);
   const [schedule, setSchedule] = useState([]);
   const [showChangeName, setShowChangeName] = useState(false);
   const [races, setRaces] = useState([]);
   const [showData, setShowData] = useState(false);
   const [timeActual, setTimeActual] = useState(null);
   const { t } = useTranslation();
   const [isOpenMail, setIsOpenMail] = useState(false);
    let identidad=propsi.identidad;
    let statusOficial=propsi.status_oficial;
    let salud=propsi.salud;

  const [showRaceAvailable, setShowRaceAvailable] = useState(true); // Estado para controlar la visibilidad del contenido

console.log("propsi desde Modal Race", propsi)


  
  const toggleChangeName = () => {
    setShowChangeName(!showChangeName);
  };


// / MODAL MAIL
  // Función para alternar el estado del modal
  const toggleOpenMail = () => {
    setIsVisibleMail(!isVisibleMail)
    setIsOpenMail(!isOpenMail);
  };


  // Función para manejar la transacción
  // const handleTransaction = async () => {
  //   try {
  //     const response = await axios.post('https://api.yourblockchain.com/transaction', {
  //       amount: 200,
  //       token: 'BHRT',
  //       // Aquí puedes incluir otros datos necesarios para la transacción
  //     });
  //     alert('Transacción exitosa: ' + response.data.message);
  //   } catch (error) {
  //     console.error('Error en la transacción:', error);
  //     alert('Error en la transacción. Inténtalo de nuevo.');
  //   }
  // };



  const handleTransaction = async (data, pro) => {
  setIsLoading_registro_oficial(true);
  console.log(data);

  const memo = `EQ- entering a ${pro.name} Mint ${pro.identidad} in race ${data.raceid}`;
  const toUsername = "equines20";
  const username = localStorage.getItem("user");
  const x = 1; // Cantidad de equinos permitidos por cuenta
  const amount = data.costo_registro;
  const symbol = data.symbol_1;


  try {
    const equinoResponse = await axios.get(`${linkApp}/equino?equineId=${pro.identidad}`);
    const equinoData = equinoResponse.data.body[0];
    const estado = equinoData.estado;
    const status = equinoData.status;

    if ((estado === "Ready" || !estado) && status === "F") {
      const raceResponse = await axios.get(`${linkApp}/race?raceid=${data.raceid}`);
      const array_owner_inscritos = raceResponse.data.body[0]?.registrados_owners || [];
      console.log("respuesta_owners", array_owner_inscritos);

      const vecesEncontrado = array_owner_inscritos.filter(owner => owner === username).length;

      if (vecesEncontrado > x) {
        console.log(`El usuario ${username} está registrado más de ${x} veces.`);
        alert(`El usuario ${username} está registrado más de ${x} veces.`);
        setIsLoading_registro_oficial(false);
        return;
      }

      if (window.hive_keychain) {
        const keychain = window.hive_keychain;
        keychain.requestSendToken(username, toUsername, amount.toFixed(3), memo, symbol, async (response) => {
          console.log("RES KeyC", response);
          try {
            await axios.post(`${linkApp}/keychaintrx`, response);
            setIsLoading_registro_oficial(false);
            setMostrarBoton_Registro(false);
            // handlee(pro);
          } catch (error) {
            console.error("Error al enviar la transacción:", error);
            alert('Error en la transacción. Inténtalo de nuevo.');
            setIsLoading_registro_oficial(false);
          }
        });
      } else {
        alert('You do not have Hive Keychain installed');
        setIsLoading_registro_oficial(false);
      }
    } else {
      alert('It seems like your horse is already registered for the race or needs rest');
      setIsLoading_registro_oficial(false);
    }
  } catch (error) {
    console.error("Error en la solicitud:", error);
    alert('Error en la solicitud. Inténtalo de nuevo.');
    setIsLoading_registro_oficial(false);
  }
};


// /  FIN MODAL MAIL

 useEffect(() => {
 if (isOpen){
  axios.get(`${linkApp}/race?status=create`)
  .then(response => setData(response.data.body))

}else{
  setData(null);
  
}
  
  }, [isOpen]);






  
    
  const handleModalContainerClick = (e) => {
    e.stopPropagation();
    closeModal();
  };



 const handleShowRaces = (e) => {
    // e.stopPropagation(); // Evita que el evento se propague
    handleFetchRace(propsi); // Acción para obtener las carreras
    setShowRaceAvailable(!showRaceAvailable); // Ocultar todo excepto el botón "Close"
  };
    
const handleFetchRace = async (propsi) => {
  if (!showData) {


 
    let c_totales = propsi.carrerasTotals || 0;
    let c_win = propsi.ganadasTotals|| 0;
    let c_win_c = propsi.ganadasCon || 0;
    let carreras = carreras_list(c_totales, c_win, c_win_c);
    let Schedule_race = propsi.Schedule;


    try {
      if (propsi.estado === "registrado") {
        Schedule_race = propsi.schedule;
        // console.log("shedul", Schedule_race);

        // Buscar la carrera en data que coincide con Schedule_race
        const scheduledRace = data.find((race) => race.raceid === Schedule_race);

        if (scheduledRace) {
          setSchedule([scheduledRace]);
          setRaces(null);
        }
      } else if (propsi.estado === "Ready" || propsi.estado === "Procesando Inscripcion") {
        // Filtrar las carreras que coinciden con la categoría del caballo (carreras)
        const filteredRaces = data.filter((race) => {
          let tip1 = race.tipo.substr(0, 2);
          let tip2 = race.tipo.substr(3, 2);
          let tip3 = race.tipo.substr(6, 2);
          return tip1 === carreras || tip2 === carreras || tip3 === carreras;
        });

        setRaces(filteredRaces);
        setSchedule(null);
      }
    } catch (error) {
      console.log("soy EL CATCH");
      console.log("error de axios", error);
    }
  } else {
    setRaces([]);
    setSchedule([]);
  }

  setShowData(!showData); // Alternar la visibilidad de la tabla de carreras y el horario
};



function traducirTipo(tipo) {
  switch (tipo) {
    case 'F':
      return 'Ready';
    case 'R':
      return 'Resting';
    case 'S':
      return 'Waiting Sell';
    case 'I':
      return 'Registered';
    case 'P':
      return 'Pending registration';
    default:
      return 'Unknown';
  }
}



function getClassName(tipo) {
  switch (tipo) {
    case 'Common':
      return 'common';
    case 'Uncommon':
      return 'uncommon';
    case 'Rare':
      return 'rare';
    case 'Spectral':
      return 'spectral';
    case 'Epic':
      return 'epic';
    case 'Mithic':
      return 'mithic';
    case 'Legendary':
      return 'legendary';
      case 'XCommon':
      return 'Xcommon';
    case 'XUncommon':
      return 'Xuncommon';
    case 'XRare':
      return 'rare';
    case 'XSpectral':
      return 'spectral';
    case 'XEpic':
      return 'epic';
    case 'XMithic':
      return 'mithic';
    case 'XLegendary':
      return 'legendary';

    default:
      return '';
  }
}




  return (
    <article className={`modal ${isOpen && "is-open"}`} >
    <div className="modal-race-container-info-ballo" >
    <div className="card-race-info">
  {propsi &&  (propsi.estado === "registrado" || propsi.estado === "Ready" || typeof propsi.estado === "undefined") && (
  !isOpenMail && ( // Aquí añadimos la condición
    <input
      type="button"
      name="show-races"
      value={showRaceAvailable ? t('race.carreras-disponibles') : t('race.carreras-disponibles-ocultar')}
      onClick={(e) => {
        e.stopPropagation(); // Detener la propagación del evento
        handleShowRaces(propsi);
      }}
      className="race-submit-button"
    />
  )
)}

<ModalCloseButton closeModal={closeModal} />

{showRaceAvailable && (
    <div className="content-race"> 
     <HorseInfo propsi={propsi} />
   
    <div className="name">
         
      <p>{propsi.name}</p> <button onClick={toggleChangeName}>🔄</button>
       
{/* ModalMail Status Oficial */}
{ (statusOficial === "M" || statusOficial === "I") && (
     <div class="button-container-mail">
  <button class="mail" onClick={toggleOpenMail}>
    <img src='./image/buttons/mail.png' alt={propsi.name} className="img-negocio" />
    <div class="notification-badge">1</div>
  </button>
</div>
)}

{/* ModalMail Status Oficial */}

{isOpenMail && (
        <div className="contenido-correo">
          {statusOficial === "M" && 
  <ModalMail 
    isOpenMail={isOpenMail} 
    toggleOpenMail={toggleOpenMail} 
    datos={propsi} 
    isVisibleMail={isVisibleMail}
  />}
          {statusOficial === "I" && 

          <ModuloM_Inscrito  isOpenMail={isOpenMail} 
    toggleOpenMail={toggleOpenMail} 
    datos={propsi} 
    isVisibleMail={isVisibleMail} />}
        </div>
      )}

      
    </div>
      <div className="name">
{showChangeName && <ChangeHorseName props={propsi.identidad}/>}
     </div>
 <div className="data-container" style={{ display: showData ? 'grid' : 'none' }}>



        </div>
        


       </div> )}

<div className="content-race">
{data && (

   schedule ?(
 <>   
 <TablaCrr data={schedule} propsi={propsi} fecha={timeActual} />
      
   </>
  ) : (
      <TablaCarreras data={races} propsi={propsi} handlee={handleFetchRace}fecha={timeActual} />
      
  )
)}
</div>

       </div>
    </div>
        

    </article>
  );
};
































export default ModalRace;

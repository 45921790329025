import React from 'react';
import './../css/PoolsTable.css'

const PoolsTable = ({ swapHive, bhrt, bhr }) => {
  return (
    <div className="MailIns-table-container">
      <table className="MailIns-pools-table">
        <thead>
          <tr>
            <th>Pool</th>
            <th>$</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>SWAP.HIVE</td>
            <td>{swapHive} SWAP.HIVE</td>
          </tr>
          <tr>
            <td>BHRT</td>
            <td>{bhrt} BHRT</td>
          </tr>
          <tr>
            <td>BHR</td>
            <td>{bhr} BHR</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PoolsTable;

import React from 'react';
import { useTranslation } from 'react-i18next';
import "./../css/EquinosTabla/equino-stats.css"
import Icon from './../../../../../../../generals/svgiconos/svgiconosenreact.jsx';


const EquinoStats = ({ equino }) => {
  const { t } = useTranslation();

  // Cálculos directos de las sumas
  const resistenciaTotal = parseInt(equino.resistencia) + (isNaN(parseInt(equino.resistencia_add)) ? 0 : parseInt(equino.resistencia_add));
  const velocidadTotal = parseInt(equino.velocidad) + (isNaN(parseInt(equino.velocidad_add)) ? 0 : parseInt(equino.velocidad_add));
  const saludTotal = parseInt(equino.animo) + parseInt(equino.alimentos_ptos);
  const implementosTotal = isNaN(parseInt(equino.implementos_ptos)) ? 0 : parseInt(equino.implementos_ptos);

  return (
    <div className="equino-stats">
      <div className="stat-item" data-tooltip={t('resultados.agilidad')}>
        <Icon name="agilidad" width="2rem" height="2rem" /> {equino.agilidad}
      </div>
      <div className="stat-item" data-tooltip={t('resultados.resistencia')}>
        <Icon name="resistencia" width="2rem" height="2rem" />{resistenciaTotal}
      </div>
      <div className="stat-item" data-tooltip={t('resultados.velocidad')}>
        <Icon name="velocidad" width="2rem" height="2rem" />  {velocidadTotal}
      </div>
      <div className="stat-item" data-tooltip={t('resultados.salud')}>
        <Icon name="corazon" width="2rem" height="2rem" />   {saludTotal}
      </div>
      <div className="stat-item" data-tooltip={t('resultados.implementos')}>
       <Icon name="horseshoes" width="2rem" height="2rem" />{implementosTotal}
      </div>
    </div>
  );
};

export default EquinoStats;

// components/Acumulados.js
import React from 'react';
import logos from './../../../../../../../../assets/logoblockhorseracing/coinBHRT2.png';
import hivelogo from './../../../../../../../../assets/logoblockhorseracing/logoHive.png';
import logosBHR from './../../../../../../../../assets/logoblockhorseracing/logoBlock.png';
import './../css/Acumulados.css'; // Importa los estilos específicos para el componente
import { useTranslation } from 'react-i18next';


const Acumulados = ({ jornadaData }) => {
  const { t } = useTranslation();

  const getLogo = (symbol) => {
    switch (symbol) {
      case 'BHRT':
        return logos;
      case 'BHR':
        return logosBHR;
      case 'SWAP.HIVE':
        return hivelogo;
      default:
        return null;
    }
  };

  return (
    <div className="section acumulado-pote">
      <div className="acumulado-title">{t("racein.jornada_5y6.Acumulados")}</div>
      <div className="acumulado-list">
        {[1, 2, 3].map((num) => (
          <div key={num} className="acumulado-item">
            <img
              src={getLogo(jornadaData[`symbol_${num}`])}
              alt={jornadaData[`symbol_${num}`]}
              className="coin-logo"
            />
            <p className="acumulado-value">
              {(Number(jornadaData[`acumulado_${num}`]) + Number(jornadaData[`pote_${num}`])).toFixed(2)}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Acumulados;

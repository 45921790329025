import React from 'react';
import { useTranslation } from 'react-i18next';


const ToggleButtons = ({ activeSection, setActiveSection }) => {
 const { t } = useTranslation();

  return (
    <div className="toggle-buttons">
      <button
        onClick={() => setActiveSection('tickets')}
        className={activeSection === 'tickets' ? 'active' : ''}
      >
       {t('sponsorArea.tickets')}
      </button>
      <button
        onClick={() => setActiveSection('sponsor')}
        className={activeSection === 'sponsor' ? 'active' : ''}
      >
        {t('sponsorArea.Patrocinio')}
      </button>
    </div>
  );
};

export default ToggleButtons;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './componentes/css/ModalPremios.css';
import { useTranslation } from 'react-i18next';
import { linkApp } from './../../../../../generals/configuracion/variablesPublicas.jsx';
import Acumulados from './componentes/modules/Acumulados';
import Premios from './componentes/modules/PremiosUnicos.jsx';
import Header from './componentes/modules/Header.jsx';
import Ganadores from './componentes/modules/Ganadores.jsx'; // Importar el módulo de ganadores



// Dentro del renderizado de tu componente principal


const ModalPremiosJ = ({ isOpenPremiosJ, closeModalPremiosJ, recentRaces }) => {
  const [jornadaData, setJornadaData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchJornada, setSearchJornada] = useState('');
  const { t } = useTranslation();

  const checkConsistentJornadas = () => {
    if (recentRaces.length === 0) return true;
    const firstJornada = recentRaces[0].jornada;
    const allSame = recentRaces.every(race => race.jornada === firstJornada);

    if (!allSame) {
      setErrorMessage('Error: Inconsistent Races Days. Reload the page and try again..');
      return false;
    }

    setSearchJornada(firstJornada); // Establece el número de jornada en el buscador
    return true;
  };

  const fetchJornadaData = async (jornada) => {
    try {
      const response = await axios.get(`${linkApp}/jornada_5y6?jornada=${jornada}`);
      console.log("Respuesta desde Jornada app data ", response.data.body.data);
      setJornadaData(response.data.body.data); // Almacena los datos de la jornada
      setErrorMessage(''); // Limpia los errores previos si la solicitud fue exitosa
    } catch (error) {
      setErrorMessage("The information couldn't be retrieved. Please try again");
      setJornadaData(null); // Limpia los datos si hay un error
    }
  };

  useEffect(() => {
    if (isOpenPremiosJ) {
      const isConsistent = checkConsistentJornadas();
      if (isConsistent && searchJornada) {
        fetchJornadaData(searchJornada);
      }
    }
  }, [isOpenPremiosJ, recentRaces]);

  const handleSearchChange = (e) => {
    setSearchJornada(e.target.value);
  };

  const handleSearchJornada = (e) => {
    if (e.key === 'Enter' && searchJornada) {
      fetchJornadaData(searchJornada);
    }
  };

  return (
    <div className={`modalPremioJ-overlay ${isOpenPremiosJ ? 'is-open' : ''}`}>
      <div className="modalPremioJ-content">
        <div className="modal-header">
        <button onClick={closeModalPremiosJ} className="close-button">
          X
        </button>
          <h2>{t('racein.ModalInfo.Jornadas')}{searchJornada}</h2>
            <a
            href="https://docs-en.blockhorseracing.com/additional-game/6-in-a-row"
            className="rules-button-premiosJ"
            target="_blank"
            rel="noopener noreferrer"
          >
            📑Rules
          </a>
        

        <input
          type="text"
          placeholder="Search"
          value={searchJornada}
          onChange={handleSearchChange}
          onKeyDown={handleSearchJornada}
          className="search-input"
        />
</div>
        {jornadaData ? (
          <div className="jornada-details">

                <Acumulados jornadaData={jornadaData} />
              
              
                <Premios jornadaData={jornadaData} />
                <Ganadores eq_ganadores={jornadaData.eq_ganadores} />

            <div className="section tickets">
              <p>{t('racein.jornada_5y6.info.TicketsSellados')}:  {jornadaData.tickets.length}</p>
            
              <p>{t('general.Races')}: {jornadaData.carreras.map((carrera) => carrera.newid).join(', ')}</p>
              <p>{t('general.Torneo')}: {jornadaData.torneo}</p>

            </div>

         

          </div>
        ) : (
          <p>{errorMessage || 'Loading race day information...'}</p>
        )}

      
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
    </div>
  );
};

export default ModalPremiosJ;
















// {
//     "_id": "672acd93a5c757e1e63c2abb",
//     "jornada": 2,
//     "acumulado_1": 0,
//     "acumulado_2": 0,
//     "acumulado_3": 2.76,
//     "carreras": [
//         {
//             "newid": 4020,
//             "level": 1,
//             "tipo_carrera": "D0",
//             "grado": "V",
//             "torneo": null
//         },
//         {
//             "newid": 4021,
//             "level": 1,
//             "tipo_carrera": "D0",
//             "grado": "V",
//             "torneo": null
//         },
//         {
//             "newid": 4022,
//             "level": 1,
//             "tipo_carrera": "D0",
//             "grado": "V",
//             "torneo": null
//         },
//         {
//             "newid": 4023,
//             "level": 1,
//             "tipo_carrera": "D0",
//             "grado": "V",
//             "torneo": null
//         },
//         {
//             "newid": 4024,
//             "level": 1,
//             "tipo_carrera": "D0",
//             "grado": "V",
//             "torneo": null
//         },
//         {
//             "newid": 4025,
//             "level": 1,
//             "tipo_carrera": "D0",
//             "grado": "V",
//             "torneo": null
//         }
//     ],
//     "eq_ganadores": [],
//     "ganadores_3": [],
//     "ganadores_4": [],
//     "ganadores_5": [],
//     "ganadores_6": [],
//     "pote_1": 20,
//     "pote_2": 5,
//     "pote_3": 20000,
//     "premio_1_final": [],
//     "premio_2_final": [],
//     "premio_3_final": [],
//     "premio_4_final": [],
//     "status": "register",
//     "status_superior": "abierto",
//     "symbol_1": "SWAP.HIVE",
//     "symbol_2": "BHR",
//     "symbol_3": "BHRT",
//     "tickets": [
//         {
//             "trx_id": "3ed96f07552a4f1f1f73a5f93981ad383ccc981e"
//         },
//     ], solo el length
//     "torneo": 2
// }
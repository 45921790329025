import React from 'react';
import { useTranslation } from 'react-i18next';

import SearchInput from './header/SearchInput';

function ModalHeader({ searchJornada, handleSearchChange, handleSearchJornada }) {
  const { t } = useTranslation();

  return (
    <div className="modal-header">
      <h2>
        {t('racein.ModalInfo.Jornadas')}
        {searchJornada}
      </h2>
      <a
        href="https://docs-en.blockhorseracing.com/additional-game/6-in-a-row"
        className="rules-button-premiosJ"
        target="_blank"
        rel="noopener noreferrer"
      >
        📑Rules
      </a>
      
      <SearchInput 
        value={searchJornada}
        onChange={handleSearchChange}
        onKeyDown={handleSearchJornada}
      />
    </div>
  );
}

export default ModalHeader;

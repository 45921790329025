// construirMemo.js
export const construirMemo = (recentRaces, selectedHorses, costoRegistro, jornada) => {
  let memo = `BHR`;
  recentRaces.forEach((race, index) => {
    const selected = selectedHorses[index].join(', ');
    memo += `${race.raceid}:- ${selected}\n  BHR`;
  });
  memo += `Total: ${costoRegistro.toFixed(3)} // j-${jornada}`;
  return memo;
};

// BotonRegistro.js
import React from "react";

const BotonRegistro = ({ symbol, costoFinal, saldo, handleRegistro, isSaldoInsuficiente, getIconBySymbol }) => {
  return (
    <button
      className="action-button"
      onClick={() => handleRegistro(symbol, costoFinal.toFixed(3))}
      disabled={isSaldoInsuficiente(symbol, costoFinal)}
    >
      <div>
        <span>
          💳 {saldo}
          <img
            src={getIconBySymbol(symbol)}
            alt={`${symbol} Token`}
            className="coin"
          />
        </span>
        <h2>{isSaldoInsuficiente(symbol, costoFinal) ? `You're running low on balance!` : 'Back a Horse!'}</h2>
        <p>{symbol} - {costoFinal.toFixed(3)}</p>
      </div>
    </button>
  );
};

export default BotonRegistro;

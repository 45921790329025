// validaciones.js
export const validarSeleccionCaballos = (selectedHorses) => {
  if (!selectedHorses || selectedHorses.length === 0) {
    return 'No se han seleccionado caballos.';
  }
  const hasEmptyRace = selectedHorses.some(horses => horses.length === 0);
  if (hasEmptyRace) {
    return 'Debes seleccionar al menos un caballo para cada carrera.';
  }
  return null;
};

export const validarCarrerasYMultiplicador = (recentRaces, selectedHorses, calculateMultiplicador) => {
  if (recentRaces.length !== selectedHorses.length) {
    return 'El número de carreras no coincide con el número de caballos seleccionados.';
  }
  const multiplicador = calculateMultiplicador();
  if (isNaN(multiplicador) || multiplicador <= 0) {
    return 'El multiplicador calculado no es válido.';
  }
  return multiplicador; // Retorna el multiplicador si es válido
};

export const validarAutenticacionYJornada = (jornada) => {
  const user = localStorage.getItem('user');
  if (!user) {
    return { error: 'El usuario no está autenticado.', user: null };
  }
  if (!jornada || typeof jornada !== 'number' || jornada <= 0) {
    return { error: 'La jornada no es válida.', user: null };
  }
  return { error: null, user };
};

// src/components/EquineBasicInfo.js
import React from 'react';
import EquineImage from './../EquineImage';
import './../css/EquinosTabla/EquineBasicInfo.css';

const EquineBasicInfo = ({ equino }) => {
  return (
    <div className="equine-basic-info">
      <div className="equine-id">
        {equino.sexo === 'M' ? '♂️' : '♀️'} {equino.tipo} {equino.equineId}
      </div>
      <EquineImage
        mainImageSrc={`./image/equinos/${equino.equineId}.png`}
        backupImageSrc={`./image/equinos/000.png`}
        className="equine-image"
      />
      <h5 className="equine-name">{equino.nameBhr}</h5>
    </div>
  );
};

export default EquineBasicInfo;

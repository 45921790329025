import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import hivelogo from './../../../../../../../../assets/logoblockhorseracing/logoHive.png';
import './../css/RaceOpenBet.css';

const RaceOpenBet = ({ carouselData, t }) => {
  const [showOfficial, setShowOfficial] = useState(true); // Estado para alternar entre tipos de carrera

  // Alterna el estado cada 5 segundos para probar
  useEffect(() => {
    const interval = setInterval(() => {
      setShowOfficial(prevShowOfficial => !prevShowOfficial); // Cambia entre true y false
      // console.log("showOfficial:", !showOfficial ? "Oficial" : "Implemento"); // Log para verificar el cambio
    }, 15000); // 5 segundos para la prueba

    return () => clearInterval(interval); // Limpia el intervalo al desmontar el componente
  }, [showOfficial]);

  return (
    <div className="RaceOpenBet">
   <h5>{showOfficial ? "Oficial" : "Casual"} {t("screen.RaceOpem")}</h5>

      {carouselData
        .filter(data => {
          const isOfficial = data.tipo_carrera === "Oficial";
          // console.log(`Filtering ${data.raceid} - Tipo: ${data.tipo_carrera}, Showing: ${showOfficial ? "Oficial" : "Implemento"}`);
          return showOfficial ? isOfficial : !isOfficial;
        })
        .map((data, index) => (
          <div key={index}>
            <h2>
              <a href={`./races#raceId=${data.raceid}&name=${data.name}`}>
                <span>{data.raceid} (🏇{data.registrados.length}) 💰</span>
                <span>
                  {data.patrocinioData
                    ? `${data.patrocinioData.Total_Pote.toFixed(2)}`
                    : '...'}
                  <img src={hivelogo} alt="Token" className="icon" style={{ width: '1em', height: '1em' }} />
                </span>
              </a>
            </h2>
          </div>
        ))}
    </div>
  );
};

// Definimos las propTypes para el componente
RaceOpenBet.propTypes = {
  carouselData: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default RaceOpenBet;

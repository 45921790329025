import React from 'react';
import HorseImage from './HorseImage'; // Asegúrate de que la ruta sea correcta
import HorseData from './HorseData'; // Asegúrate de que la ruta sea correcta
import "./../css/horseinfo/Base.css";






function getClassName(tipo) {
  switch (tipo) {
    case 'Common':
      return 'common';
    case 'Uncommon':
      return 'uncommon';
    case 'Rare':
      return 'rare';
    case 'Spectral':
      return 'spectral';
    case 'Epic':
      return 'epic';
    case 'Mithic':
      return 'mithic';
    case 'Legendary':
      return 'legendary';
      case 'XCommon':
      return 'Xcommon';
    case 'XUncommon':
      return 'Xuncommon';
    case 'XRare':
      return 'rare';
    case 'XSpectral':
      return 'spectral';
    case 'XEpic':
      return 'epic';
    case 'XMithic':
      return 'mithic';
    case 'XLegendary':
      return 'legendary';

    default:
      return '';
  }
}


const HorseInfo = ({ propsi }) => (
  <div className="caballo-info-imagen">
   <HorseImage
      imageSrc={propsi.IPFS}
      backupSrc={`/image/equinos/${propsi.identidad}.png`}
      className={getClassName(propsi.tipo)}
    />
    <HorseData data={propsi} />
  </div>
);

export default HorseInfo;

import React from 'react';
import Icon from './../../../generals/svgiconos/svgiconosenreact.jsx';
import './../css/SponsorHorseCard.css';

const SponsorHorseCard = ({ horse, openModalSponsorBallo }) => {
  return (
    <li key={horse.equineId} onClick={() => openModalSponsorBallo(horse)} className="horse-item">
      <div className="horse-header">
        <span className="horse-id">ID: {horse.equineId}</span>
        <span className="horse-type">{horse.tipo}</span>
      </div>
      Attributes
      <div className="horse-attributes">

<span 
  className="horse-speed" 
  data-value={((Number(horse.velocidad) || 0) + (Number(horse.velocidad_add) || 0)).toFixed(0)}
>
  <Icon name="velocidad" className="svg" alt="Speed" />
</span>

<span 
  className="horse-endurance" 
  data-value={((Number(horse.resistencia) || 0) + (Number(horse.resistencia_add) || 0)).toFixed(0)}
>
  <Icon name="resistencia" className="svg" alt="Endurance" />
</span>

  <span className="horse-agility" data-value={horse.agilidad}>
    <Icon name="agilidad" className="svg" alt="Agility" />
  </span>
</div>

    </li>
  );
};

export default SponsorHorseCard;


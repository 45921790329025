import React from 'react';
import Icon from './../../../../../../generals/svgiconos/svgiconosenreact.jsx';
import "./../css/horseinfo/HorseData.css";



function traducirTipo(tipo) {
  switch (tipo) {
    case 'F':
      return 'Ready';
    case 'R':
      return 'Resting';
    case 'S':
      return 'Waiting Sell';
    case 'I':
      return 'Registered';
    case 'P':
      return 'Pending registration';
    default:
      return 'Unknown';
  }
}










const HorseData = ({ data }) => {
  // Función auxiliar para calcular los puntos sumando los adicionales
  const calcularPuntos = (base, adicional = 0) => parseInt(base) + parseInt(adicional || 0);

  // Definir los atributos a mostrar con sus íconos y valores calculados
  const atributos = [
    { nombre: 'animo', valor: calcularPuntos(data.animo, data.alimentos_ptos) },
    { nombre: 'velocidad', valor: calcularPuntos(data.velocidad, data.velocidad_add) },
    { nombre: 'resistencia', valor: calcularPuntos(data.resistencia, data.resistencia_add) },
    { nombre: 'agilidad', valor: data.agilidad },
  ];

  return (
    <>
      <p className="Status">
        {data?.status?.split(',').map(traducirTipo).join(', ')}
      </p>

      <p className="Tipo">
        {data.tipo}
        <span style={{ fontSize: '1.4rem' }}>{data.sexo === 'M' ? '♂️' : '♀️'}</span>
      </p>

      <div className="trapecio-inforace">
        {atributos.map((atributo) => (
          <div key={atributo.nombre}>
            <Icon name={atributo.nombre} width="2rem" height="2rem" />
            <p>{atributo.valor}</p>
          </div>
        
        ))}
    
      </div>
      <div className="minteo-race">
    
    <p className="Mint">
    Mint <h2>{data.identidad}</h2>
    </p>
             </div>
    </>
  );


};



export default HorseData;
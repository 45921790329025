// registroService.js
import { linkApp } from './../configuracion/variablesPublicas.jsx';

export const registrarCaballo = async (datosCaballo) => {
  try {
    // Obtén el habilitador desde localStorage
    let habilitador = localStorage.getItem("user");
   
   // Desestructura el objeto y elimina los campos no deseados
    const { Carreras_Utiles, Dias_Vida,IPFS,Nacimiento,alimentos,alimentos_ptos,alimentos_status,carreras,carreras_second,carreras_third,consecutivas_win,consecutivas_lose,entrenamiento_gratis,estado,for_shedule,generacion,implementos_ptos,implementos_status,lastRace,opcion_entrenamiento,resistencia,resistencia_add,schedule,status,velocidad,velocidad_add, ...datosRestantes } = datosCaballo;

  
 

    // Extiende el objeto datosRestantes para incluir el habilitador
    const bodyData = {
      ...datosRestantes,  // Incluye todos los datos restantes del caballo
      habilitador         // Agrega el habilitador
    };
    // Aquí haces una llamada a la API o cualquier lógica de registro
    const response = await fetch(`${linkApp}/registro_torneo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyData),  // Envía el nuevo objeto que incluye el habilitador
    });

    if (!response.ok) {
      throw new Error('Error en el registro');
    }

    const data = await response.json();
    return { success: true, data };
  } catch (error) {
    console.error('Error al registrar el caballo:', error);
    return { success: false, error: error.message };
  }
};






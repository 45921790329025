import React from 'react';

const TicketList = ({ tickets, openModal }) => {
  return (
    <div className="TicketModule">
      {/*<h2>Boletos -Jornada"📅" {tickets[0].jornada}</h2>*/}
      <h2>Tickets - Event Day📅 {tickets.length > 0 ? tickets[0].jornada : "Loading..."}</h2>

      <ul>
        {Array.isArray(tickets) && tickets.length > 0 ? (
          tickets.map(ticket => (
            <li key={ticket.trx_id} onClick={() => openModal(ticket)}>
              {ticket.trx_id.slice(-8)}   (📅:{ticket.jornada}) {/* Últimos 8 caracteres del ID */}
            </li>
          ))
        ) : (
          <p>There are no tickets available</p>
        )}
      </ul>

      
    </div>
  );
};

export default TicketList;
